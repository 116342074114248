<template>
 <v-card>
  <v-card-title class="headline">
   <slot name="title">{{ title }} {{ type === 1 ? driverDetails.name : vehicleDetails.vehicleno }}</slot>
  </v-card-title>
  <v-card-text>
   <slot name="body">
    <v-textarea v-model="remarks" label="Remarks" rows="3" dense></v-textarea>
   </slot>
  </v-card-text>
  <v-card-actions style="justify-content: flex-end">
   <v-btn color="#ed5e5e" @click="closeModalHandler">Cancel</v-btn>
   <v-btn color="#eea004" @click="confirmActionHandler" :disabled="!remarks">Confirm</v-btn>
  </v-card-actions>
 </v-card>
</template>

<script>
import axios from "@/axios/axios-global";
import moment from "moment";
export default {
 name: "DeactivationModal",
 props: {
  title: {
   type: String,
   required: true,
   default: "",
  },
  token: String,
  type: Number,
 },
 emits: ["close-modal"],
 data() {
  return {
   remarks: "",
   driverDetails: {},
   vehicleDetails: {},
   drawer: true,
  };
 },
 mounted() {

 if(this.title!=='Reject')
 {
    if (this.type === 1) {
   this.getDriverDetails();
  } else {
   this.getVehicleDetails();
  }
 }

 },
 methods: {
  async getDriverDetails() {
   const payload = {
    driverId: this.token,
   };
   const response = await axios.post("driver/details", payload);
   console.log(response);
   this.driverDetails = response.data;
  },
  async getVehicleDetails() {
   const response = await axios.post("vehicle/details", { vehicleId: this.token });
   this.vehicleDetails = response.data;
  },
  closeModalHandler() {
   this.remarks = "";
   this.$emit("dialog-closed");
  },
  async confirmActionHandler() {
    if(this.title !== 'Reject')
  {
   if (this.type === 1) {
    let url = "driver/deactivateDriver";
    if (this.driverDetails.trash) url = "driver/activate";
    const index = this.driverDetails.reason.length + 1 || 1;
    const payload = {
     driverId: this.driverDetails.token,
     id: index,
     date: moment.now(),
     reason: this.remarks,
     remarksBy: {
      name: localStorage.getItem("vendorName"),
     },
    };
    const response = await axios.post(url, payload);
    if (response.status === 200) {
     this.$emit("change-status");
     this.$toasted.show(response.data, {
      position: "bottom-center",
      duration: 4000,
     });
    } else {
     this.$toasted.show(response.data, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   } else {
    let url = "vehicle/deactivate";
    if (this.vehicleDetails.trash) url = "vehicle/activate";
    const payload = {
     vehicleId: this.vehicleDetails.token,
     reason: this.remarks,
     user: localStorage.getItem("vendorName"),
    };
    const response = await axios.post(url, payload);
    if (response.status === 200) {
     this.$emit("change-status");
     this.$toasted.show(response.data, {
      position: "bottom-center",
      duration: 4000,
     });
    } else {
     this.$toasted.show(response.data, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
}else{
    this.$emit('callReject',this.remarks)
}
  },
 },
};
</script>

<style scoped>
.v-card {
 padding: 10px;
}
.headline {
 font-family: "QuickSand" !important;
 color: #fa7815;
 font-size: 1.2rem !important;
 font-weight: bold;
}
</style>
