<template>
 <v-list-item>
  <div class="drawer-content">
   <div class="details-block">
    <v-list v-if="Object.keys(trip).length > 0">
     <v-list-item class="driver-card">
      <div class="driver-details">
       <div class="status-block"></div>
       <div class="image-row">
        <span><img alt="" src="https://taski2020.s3.ap-south-1.amazonaws.com/noimage/noimage.png" /></span>
       </div>

       <div class="details">
        <span>{{ trip.driver.name }}</span>
        <span>{{ trip.driver.mobile }}</span>
       </div>
      </div>
     </v-list-item>
     <v-list-item v-if="trip.tripid">
      <div class="location-details">
       <div v-for="source in trip.locations" class="location-row">
        <div class="icon-column" :class="{ destination: source.type === 'destination' }"><div class="inner-icon"></div></div>
        <div class="location-text">
         <span>{{ source.address }}</span>
         <!-- <span>{{ trip.locations[0].value.address }}</span> -->
        </div>
       </div>
       <div class="arrow-row">
        <div class="arrow"></div>
       </div>
       <!-- <div v-for="destination in trip.locations" class="location-row">
        <div class="icon-column destination"><div class="inner-icon"></div></div>
        <div class="location-text">
            
         <span>{{ destination.address }}</span>
        </div>
       </div> -->
      </div>
     </v-list-item>

     <v-list-item v-if="trip.tripid">
      <v-list-item-content>
       <v-list-item-title>Trip Details</v-list-item-title>
       <div class="trip-container">
        <div class="trip-details-block">
         <span>Trip Id</span>
         <span>{{ trip.tripid }}</span>
        </div>
        <div class="trip-details-block">
         <span>Trip Status</span>
         <span>{{ trip.status.name }}</span>
        </div>
        <div class="trip-details-block">
         <span>Vehicle No</span>
         <span>{{ trip.vehicle.vehicleno }}</span>
        </div>
        <div class="trip-details-block">
         <span>Vehicle Model</span>
         <span>{{ trip.vehicle.manufacturer.name }} {{ trip.vehicle.model.name }}</span>
        </div>
        <div class="trip-details-block trip-location">
         <span>Source</span>

         <span> {{ trip.locations[0].address }}</span>
        </div>
        <div class="trip-details-block trip-location">
         <span>Destination</span>
         <span>{{ trip.locations[trip.locations.length - 1].address }}</span>
        </div>
       </div>
      </v-list-item-content>
     </v-list-item>
    </v-list>
   </div>
  </div>
 </v-list-item>
</template>

<script>
import axios from "@/axios/axios-global";

export default {
 name: "DriverDrawer",
 props: {
  tripDetails: Object,
 },
 data() {
  return {
   trip: {},
  };
 },
 mounted() {
  this.getTripDetails();
 },
 methods: {
  async getTripDetails() {
   const payload = {
    token: this.tripDetails.token,
    tripId: this.tripDetails.tripid,
   };
   const response = await axios.post("map/drawer/list", payload);
   this.trip = response.data;
  },
 },
};
</script>

<style lang="scss" scoped>
.drawer-content {
    width: 100%;
 .details-block {
  margin: 10px 0;

  .lcoation-block {
   position: relative;
  }
  ::v-deep .v-list-item {
   margin-top: 10px;
   border-radius: 10px;
   border: 1px solid rgb(219, 219, 219);
   padding: 20px 15px;

   .v-list-item__title {
    font-size: 1.5rem;
    padding-bottom: 5px;
    border-bottom: 1px solid rgb(205, 205, 205);
    font-weight: 600;
   }
  }
  .driver-card {
   .driver-details {
    display: flex;
    padding: 5px 0;
    .image-row {
     overflow: hidden;
     width: 45px;
     height: 45px;
     position: relative;
     box-sizing: content-box;
     border: #ffffff solid 4px;
     outline: 1px solid #d4d4d4;
     line-height: 1px;
     border-radius: 50%;
     background: #ffffff;
     margin-right: 15px;

     img {
      width: 100%;
      height: 100%;
      object-fit: cover;
     }
    }
    .details {
     display: grid;
     //  span {
     //   text-transform: capitalize;
     //  }
    }
   }
  }

  .location-row {
   display: flex;
   gap: 10px;
   margin-top: 10px;

   align-items: flex-end;
   &:first-child {
    align-items: flex-start;
   }

   .icon-column {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #166c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    .inner-icon {
     background: #219913;
     height: 10px;
     width: 10px;
     border-radius: 50%;
    }
   }
   .destination {
    background: #d71010;
    margin-top: 10px;

    .inner-icon {
     background: #f05555;
    }
   }
   .location-text {
    width: 90%;
    span {
     font-weight: bold;
     font-size: 13px;
    }
   }
  }
  .trip-container {
   display: flex;
   flex-wrap: wrap;
  }
  .trip-details-block {
   display: flex;
   flex-direction: column;
   gap: 5px;
   width: 100%;
   padding: 15px 0;
   width: 50%;
   span {
    &:first-child {
     font-size: 14px;
     font-weight: 600;
     color: grey;
     text-transform: uppercase;
    }
   }
  }
  .trip-location {
   width: 100%;
  }
  .arrow-row {
   position: absolute;
   left: 27px; /* Aligns with the marker's center */
   top: 30px; /* Adjust as needed for proper vertical alignment */
   width: 2px;
   height: calc(100% - 50px); /* Dynamically adjusts height to connect markers */
   background-color: #7c7c7c;
   z-index: 0; /* Ensures the line is behind the markers */
  }
 }
}
</style>
