<template>
 <div class="document-insert-content-modal">
  <span class="header-modal">Upload Documents</span>
  <div class="document-upload-modal" v-if="token">
   <v-card class="photo-insert-modal" elevation="2">
    <input accept="image/*,application/pdf" ref="photoUpload" @change="insertPhoto" type="file" class="input-blk" />
    <div class="image-upload-modal" @click="$refs.photoUpload.click()" v-if="photoImage === ''">
     <i class="fas fa-images"></i>
     <span>Upload Image</span>
    </div>
    <div class="image-upload-modal" v-if="photoImage !== ''">
     <v-btn v-if="isPdf(photoImage)" @click="previewImage(photoImage)" small class="p-absolute">View PDF</v-btn>
     <img :src="photoImage" class="image-view-blk" v-if="!isPdf(photoImage)" @click="previewImage(photoImage)" />
     <object v-else :data="photoImage" width="100%" height="500px"></object>
    </div>
    <div class="image-name-blk">
     <span>Photo</span>
     <div class="buttons" v-if="photoImage">
      <v-chip readonly outlined :color="getExpiredStatus(documents.photo.verified).color" v-if="documents && documents.photo">{{ getExpiredStatus(documents.photo.verified).text }}</v-chip>
      <v-btn small color="#83b9e5" @click="$refs.photoUpload.click()" v-if="photoImage !== '' && documents.photo.text !== 'Verified'">Edit<i class="fas fa-pen"></i></v-btn>
     </div>
    </div>
   </v-card>
   <v-card class="photo-insert-modal" elevation="2">
    <input accept="image/*,application/pdf" ref="idProof" @change="insertIdProof" type="file" class="input-blk" />
    <div class="image-upload-modal" @click="$refs.idProof.click()" v-if="idImage === ''">
     <i class="fas fa-images"></i>
     <span>Upload Image</span>
    </div>
    <div class="image-upload-modal" v-if="idImage !== ''">
     <v-btn v-if="isPdf(idImage)" @click="previewImage(idImage)" small class="p-absolute">View PDF</v-btn>
     <img :src="idImage" class="image-view-blk" v-if="!isPdf(idImage)" @click="previewImage(idImage)" />
     <object v-else :data="idImage" width="100%" height="500px"></object>
    </div>
    <div class="image-name-blk">
     <span>ID Proof</span>
     <div class="buttons" v-if="idImage">
      <v-chip readonly outlined :color="getExpiredStatus(documents.idproof.verified).color" v-if="documents && documents.idproof">{{ getExpiredStatus(documents.idproof.verified).text }}</v-chip>
      <v-btn small color="#83b9e5" @click="$refs.idProof.click()" v-if="idImage !== '' && documents.idproof !== 'Verified'">Edit<i class="fas fa-pen"></i></v-btn>
     </div>
    </div>
   </v-card>
   <!--      <v-card class="photo-insert-modal" elevation="2" >-->
   <!--        <input accept="image/*" ref="panProof" @change="insertPan" type="file" class="input-blk">-->
   <!--        <div class="image-upload-modal" @click="$refs.panProof.click()" v-if="panImage === ''">-->
   <!--          <i class="fas fa-images"></i>-->
   <!--          <span>Upload Image</span>-->
   <!--        </div>-->
   <!--        <div class="image-upload-modal" v-if="panImage !== ''">-->
   <!--          <img :src="panImage" class="image-view-blk">-->
   <!--        </div>-->
   <!--        <span class="image-name-blk">Pan Card</span>-->
   <!--      </v-card>-->
   <v-card class="photo-insert-modal" elevation="2">
    <input accept="image/*,application/pdf" ref="licenceFront" @change="insertLicenceFront" type="file" class="input-blk" />
    <div class="image-upload-modal" @click="$refs.licenceFront.click()" v-if="!licenceFrontImage">
     <i class="fas fa-images"></i>
     <span>Upload Image</span>
    </div>
    <div class="image-upload-modal" v-if="licenceFrontImage !== ''">
     <v-btn v-if="isPdf(licenceFrontImage)" @click="previewImage(licenceFrontImage)" small class="p-absolute">View PDF</v-btn>
     <img :src="licenceFrontImage" v-if="!isPdf(licenceFrontImage)" @click="previewImage(licenceFrontImage)" class="image-view-blk" />
     <object v-else :data="licenceFrontImage" width="100%" height="500px"></object>
    </div>
    <div class="image-name-blk">
     <span>Licence Front</span>
     <div class="buttons" v-if="licenceFrontImage">
      <v-chip readonly outlined :color="getExpiredStatus(documents.licensefront.verified).color" v-if="documents && documents.licensefront">{{
       getExpiredStatus(documents.licensefront.verified).text
      }}</v-chip>
      <v-btn small color="#83b9e5" @click="$refs.licenceFront.click()" v-if="licenceFrontImage !== '' && documents.licensefront !== 'Verified'">Edit<i class="fas fa-pen"></i></v-btn>
     </div>
    </div>
   </v-card>
   <v-card class="photo-insert-modal" elevation="2">
    <input accept="image/*" ref="licenceBack" @change="insertLicenceBack" type="file" class="input-blk" />
    <div class="image-upload-modal" @click="$refs.licenceBack.click()" v-if="licenceBackImage === ''">
     <i class="fas fa-images"></i>
     <span>Upload Image</span>
    </div>
    <div class="image-upload-modal" v-if="licenceBackImage !== ''">
     <v-btn v-if="isPdf(licenceBackImage)" @click="previewImage(licenceBackImage)" small class="p-absolute">View PDF</v-btn>
     <img :src="licenceBackImage" class="image-view-blk" v-if="!isPdf(licenceBackImage)" @click="previewImage(licenceBackImage)" />
     <object v-else :data="licenceBackImage" width="100%" height="500px"></object>
    </div>
    <div class="image-name-blk">
     <span>Licence Back</span>
     <div class="buttons" v-if="licenceBackImage">
      <v-chip readonly outlined :color="getExpiredStatus(documents.licenseback.verified).color" v-if="documents && documents.licenseback">{{
       getExpiredStatus(documents.licenseback.verified).text
      }}</v-chip>
      <v-btn small color="#83b9e5" @click="$refs.licenceBack.click()" v-if="licenceBackImage !== '' && documents.licenseback !== 'Verified'">Edit<i class="fas fa-pen"></i></v-btn>
     </div>
    </div>
   </v-card>
   <v-card class="photo-insert-modal" elevation="2">
    <input accept="image/*" ref="policeClearance" @change="insertPoliceClearance" type="file" class="input-blk" />
    <div class="image-upload-modal" @click="$refs.policeClearance.click()" v-if="policeClearanceImage === ''">
     <i class="fas fa-images"></i>
     <span>Upload Image</span>
    </div>
    <div class="image-upload-modal" v-if="policeClearanceImage !== ''">
     <v-btn v-if="isPdf(policeClearanceImage)" @click="previewImage(policeClearanceImage)" small class="p-absolute">View PDF</v-btn>
     <img :src="policeClearanceImage" class="image-view-blk" v-if="!isPdf(policeClearanceImage)" @click="previewImage(policeClearanceImage)" />
     <object v-else :data="policeClearanceImage" width="100%" height="500px"></object>
    </div>
    <div class="image-name-blk">
     <span>Police Clearance</span>
     <div class="buttons" v-if="policeClearanceImage">
      <v-chip readonly outlined :color="getExpiredStatus(documents.policeclearance.verified).color" v-if="documents && documents.policeclearance">{{
       getExpiredStatus(documents.policeclearance.verified).text
      }}</v-chip>
      <v-btn small color="#83b9e5" @click="$refs.policeClearance.click()" v-if="policeClearanceImage !== '' && documents.policeclearance !== 'Verified'">Edit<i class="fas fa-pen"></i></v-btn>
     </div>
    </div>
   </v-card>
  </div>
  <div v-else class="no-driver-document">No data Available</div>

  <div class="button-row">
   <v-btn color="#ed5e5e" @click="cancelSubmit">Close</v-btn>
  </div>

  <!-- Image Preview Dialog -->
  <vue-easy-lightbox :visible="imagePreviewDialog" :imgs="[imageToPreview]" @hide="imagePreviewDialog = false" v-if="!isPdf(imageToPreview)"></vue-easy-lightbox>

  <v-dialog v-model="imagePreviewDialog" class="preview-board" width="700px" max-height="100%" v-if="isPdf(imageToPreview)">
   <iframe width="100%" :src="imageToPreview" style="height: 100vh; overflow: auto"></iframe>
  </v-dialog>
  <Loading v-if="loading"></Loading>
 </div>
</template>

<script>
import axios from "@/axios/axios-global";
import * as moment from "moment";
import Loading from "@/components/common/Loading";
import { encryptUtil } from '../../../Utils/decrypt'

export default {
 name: "DocumentInsert",
 components: { Loading },
 props: {
  token: String,
  resetTrigger: String,
 },
 data: () => {
  return {
   photoImage: "",
   idImage: "",
   licenceFrontImage: "",
   licenceBackImage: "",
   policeClearanceImage: "",
   documents: "",
   loading: false,
   IDExpiryDate: null,
   LncExpiryDate: null,
   POCExpiryDate: null,
   imageToPreview: "",
   imagePreviewDialog: false,

   driverDetails: {},

   documentStatus: [
    { id: 0, text: "Rejected", color: "#EB6746" },
    { id: 1, text: "Verified", color: "#0DA300" },
    { id: 2, text: "Pending", color: "#FF9800" },
    { id: 3, text: "Expiring soon", color: "red" },
    { id: 4, text: "Expired", color: "#EB6746" },
   ],
  };
 },
 methods: {
  getExpiredStatus(status) {
   return this.documentStatus.find((item) => item.id === status);
  },
  validateExpiry(model, reference, open) {
   if (!model) {
    this.$toasted.show("Please select the expiry date first", {
     position: "bottom-center",
     duration: 4000,
    });
    return false;
   } else {
    if (open) {
     this.$refs[reference].click();
    } else {
     return true;
    }
   }
  },
  resetDetails(data) {
   this.loading = true;
   let default_val = {
    color: "",
    text: "",
    verified: null,
    image: "",
   };
   this.documents = {
    photo: default_val,
    idproof: default_val,
    licencefront: default_val,
    licenseback: default_val,
    policeclearance: default_val,
   };
   this.IDExpiryDate = "";
   this.LncExpiryDate = "";
   this.POCExpiryDate = "";
   if (data.load_data) {
    console.log("object");
    this.getDriverDetails();
   }
  },
  assignDetails() {
   this.photoImage = this.documents.photo.image;
   this.idImage = this.documents.idproof.image;
   this.IDExpiryDate = this.documents.idproof.expdate ? this.documents.idproof.expdate : "";
   this.licenceFrontImage = this.documents.licensefront.image;
   this.LncExpiryDate = this.documents.licensefront.expdate ? this.documents.licensefront.expdate : "";
   this.licenceBackImage = this.documents.licenseback.image;
   this.policeClearanceImage = this.documents.policeclearance.image;
   this.POCExpiryDate = this.documents.policeclearance.expdate ? this.documents.policeclearance.expdate : "";
  },
  previewImage(imageSrc) {
   this.imageToPreview = imageSrc;
   this.imagePreviewDialog = true;
  },
  isPdf(url) {
   return url && url.toLowerCase().includes(".pdf");
  },
  async getDriverDetails() {
   if (this.token) {
    this.loading = true;
    const response = await axios.post("driver/details", { driverId: this.token });
    this.driverDetails = response.data;
    this.documents = response.data.document;
    this.assignDetails();
    this.loading = false;
   }
  },
  async validateDocument(document) {
   document.token = this.token;
   const response = await axios.post("driver/document/verify", document);
   if (response && response.status === 200) {
    this.$toasted.show(response.data, {
     position: "bottom-center",
     duration: 4000,
    });
    this.getDriverDetails();
   } else {
    this.$toasted.show(response.data, {
     position: "bottom-center",
     duration: 4000,
    });
   }
  },
  async insertPhoto(event) {
   let files = event.target.files[0];
   // this.photoImage = URL.createObjectURL(files)
   this.loading = true;
   if (event.target.files.length > 0) {
    let formData = new FormData();
    const payload = {
     driverId: this.token,
     imageName: "photo",
    };
    const setencryptUtil =  new encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    formData.append("image", files);
    const response = await axios.patch("driver/document/update", formData);
    this.loading = false;

    if (response.status === 200) {
     setTimeout(async () => {
        this.$emit('recalling')
      await this.getDriverDetails();
     }, 500);
     this.$toasted.show(response.data.message, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
  },
  async insertIdProof(event) {
   let files = event.target.files[0];
   this.loading = true;
   // this.idImage = URL.createObjectURL(files)
   if (event.target.files.length > 0) {
    let formData = new FormData();
    const payload = {
     driverId: this.token,
     imageName: "idProof",
     expdate: this.IDExpiryDate,
    };
    const setencryptUtil =  new encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    formData.append("image", files);
    const response = await axios.patch("driver/document/update", formData);

    this.loading = false;
    if (response.status === 200) {
     setTimeout(async () => {
        this.$emit('recalling')
      await this.getDriverDetails();
     }, 500);
     this.$toasted.show(response.data.message, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
  },
  // async insertPan(event) {
  //   let files = event.target.files[0]
  //   this.panImage = URL.createObjectURL(files)
  // },
  async insertLicenceFront(event) {
   let files = event.target.files[0];
   this.loading = true;
   // this.licenceFrontImage = URL.createObjectURL(files)
   if (event.target.files.length > 0) {
    let formData = new FormData();
    const payload = {
     driverId: this.token,
     imageName: "licenceFront",
     licenceNo: this.licenceNo,
     expdate: this.LncExpiryDate,
    };
    const setencryptUtil =  new encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    formData.append("image", files);
    const response = await axios.patch("driver/document/update", formData);
    this.loading = false;
    if (response.status === 200) {
     setTimeout(async () => {
        this.$emit('recalling')
      await this.getDriverDetails();
     }, 500);
     this.$toasted.show(response.data.message, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
  },
  async insertLicenceBack(event) {
   let files = event.target.files[0];
   this.loading = true;
   // this.licenceBackImage = URL.createObjectURL(files)
   if (event.target.files.length > 0) {
    let formData = new FormData();
    const payload = {
     driverId: this.token,
     imageName: "licenceBack",
    };
    const setencryptUtil =  new encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    formData.append("image", files);
    const response = await axios.patch("driver/document/update", formData);
    this.loading = false;
    if (response.status === 200) {
     setTimeout(async () => {
        this.$emit('recalling')
      await this.getDriverDetails();
     }, 500);
     this.$toasted.show(response.data.message, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
  },
  async insertPoliceClearance(event) {
   let files = event.target.files[0];
   this.loading = true;
   // this.policeClearanceImage = URL.createObjectURL(files)
   if (event.target.files.length > 0) {
    let formData = new FormData();
    const payload = {
     driverId: this.token,
     imageName: "policeClearance",
     expdate: this.POCExpiryDate,
    };
    const setencryptUtil =  new encryptUtil({ decrypt: payload });
    const data=setencryptUtil.getEncryptedData()
    formData.append("data", data);
    formData.append("image", files);
    const response = await axios.patch("driver/document/update", formData);
    this.loading = false;
    if (response.status === 200) {
     setTimeout(async () => {
        this.$emit('recalling')
      await this.getDriverDetails();
     }, 500);
     this.$toasted.show(response.data.message, {
      position: "bottom-center",
      duration: 4000,
     });
    }
   }
  },

  cancelSubmit() {
   this.$emit("cancel-insert");
  },
 },
 mounted() {
  this.resetDetails({ load_data: true });
 },
};
</script>

<style scoped lang="scss">
@import "./documentInsert";
.invoice-content-modal {
 text-align: center;
 width: 100%;
 z-index: 100;
 .image-preview {
  width: 100%;
  object-fit: contain;
  padding: 10px;
 }
}

.button-row {
 position: sticky;
 display: flex;
 justify-content: flex-end;
 bottom: 0;
 background-color: white;
 padding-bottom: 20px;
}

.header-modal {
 color: #fa7815;
 font-weight: bold;
 font-size: 17px;
 padding: 5px;
 width: 100%;
 border-bottom: 1px solid #cacaca;
 text-transform: uppercase;
}
.preview-board {
 background-size: cover;
 .preview-cover {
  max-width: 500px;
 }
}
.headline {
 display: flex;
 justify-content: flex-end;
 .v-btn {
  background-color: #f44336;
  color: white;
 }
}
</style>
