<template>
  <v-dialog
      v-model="dialogView"
      persistent
      max-width="300"
      style="z-index: 200"
  >
    <v-card>
      <v-toolbar :color="options.color">
        <i :class="options.icon"></i>
        <v-toolbar-title>{{ title }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-show="!!message">{{ message }}</v-card-text>
      <v-card-actions>
        <v-btn class="confirm-button-blk"  color="#8f8f8f" @click="agree" @keydown.enter="agree">{{ options.buttonName }}</v-btn>
        <v-btn class="confirm-button-blk" color="#eb9191" @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "Confirm",
  data: () => {
    return {
      dialogView: false,
      title: null,
      message: null,
      resolve: null,
      reject: null,
      options: {
        color : 'blue',
        icon: 'fas fa-thumbs-up',
        buttonName: 'Yes',
      }
    }
  },
  methods: {
    open(title, message,options) {
      this.dialogView = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    agree() {
      this.resolve(true)
      this.dialogView = false
    },
    cancel() {
      this.resolve(false)
      this.dialogView = false
    }
  }
}
</script>

<style scoped lang="scss">
.v-dialog > .v-card > .v-card__subtitle, .v-dialog > .v-card > .v-card__text {
  text-align: center;
  padding: 20px 25px;
}

//.button.primary--text {
//  width: 90px;
//  color: #ffffff;
//  margin: 0 5px;
//  background: #e0a913;
//}

.v-dialog .theme--light.v-toolbar.v-sheet {
  color: #ffffff;
  box-shadow: none;
}

.v-dialog {
  overflow: hidden;
}

.confirm-button-blk {
  width: 90px;
  background: #989194;
  margin: 0 5px;
  color: #ffffff;
}

.v-dialog .v-toolbar {
  height: auto !important;
  font-weight: 700;
  padding: 15px;
}

::v-deep .v-toolbar__content, .v-toolbar__extension  {
  flex-direction: column;
  justify-content: center;
  height: auto !important;
  display: flex;
}

.v-dialog .v-toolbar__content i {
  font-size: 2rem;
  margin: 0 0 10px;
}

.v-overlay--active .v-overlay__scrim {
  opacity: 0.73 !important;
}

.v-dialog .v-card__actions {
  justify-content: center;
  padding: 0 25px 25px;
}

.v-toolbar__title {
  white-space: inherit;
  text-align: center;
}
</style>