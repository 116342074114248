import axios from 'axios'

const instance = axios.create({
    // baseURL: 'https://www.taskicabs.com/trip/'
    baseURL: 'https://staging.taskicabs.com/trip/'
//  baseURL: 'http://192.168.29.55:2020/trip/',
})

const token = localStorage.getItem('vendorLoginToken');
if(token) {
    instance.defaults.headers.common['token'] = token;
}
export default instance
