import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'

import VueRouter from "vue-router";

import '@fortawesome/fontawesome-free/css/all.min.css';

import {routes} from "@/router";

import instance from "@/axios/axios-global";

import Lightbox from 'vue-easy-lightbox'

import {VueUtil} from './components/common/VueUtils'
Vue.mixin(VueUtil);
import VueExcel from './components/common/VueExcel'
Vue.prototype.$VueExcel = VueExcel;
Vue.component('VueExcel', VueExcel);

import Toasted from 'vue-toasted';
import toast from "@/components/common/toast";
import { decryptUtil, encryptUtil } from "./Utils/decrypt";
Vue.prototype.$decryptUtil = decryptUtil;
Vue.prototype.$encryptUtil = encryptUtil;

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(Toasted)
Vue.use(Lightbox)

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});

router.beforeEach(
    (to, from, next) => {
        let retrievedObject = localStorage.getItem('vendorLoginToken');
        let vendorLoginStatus = localStorage.getItem('vendorStatus')
        if (retrievedObject === null) {
            if (to.path === '/login') {
                next()
            } else {
                router.push('/login')
                next()
            }
        } else {
            // if(to.path)
            if (to.path === '/login' ) {
                    router.push('/dashboard')
                    next()
            } else if(to.path === '/' ) {
                router.push('/dashboard')
                next()
            }
            next()
        }

    }
)

instance.interceptors.response.use((response) => {
    // if (response.status === 401) {
    //     instance.defaults.headers.common['token'] = "";
    //
    //     localStorage.removeItem('vendorLoginToken')
    //     localStorage.removeItem('vendorName')
    //     localStorage.removeItem('vendorNumber')
    //     localStorage.removeItem('vendorStatus')
    //
    //     this.$router.push('/login')
    // }
    return response;
},error => {
    toast.error(error.response.data)
    if (error.response.status === 401) {
        instance.defaults.headers.common['token'] = "";

        localStorage.removeItem('vendorLoginToken')
        localStorage.removeItem('vendorName')
        localStorage.removeItem('vendorNumber')
        localStorage.removeItem('vendorStatus')

       router.push('/login')
    }
})


new Vue({
    vuetify,
    router,
    render: function (h) {
        return h(App)
    }
}).$mount('#app')
