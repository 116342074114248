<template>
  <div class="trip-toll-modal">
    <v-card class="toll-card">
      <span class="toll-header">Toll</span>
      <div class="toll-list-content">
        <div class="toll-list" v-for="(data,index) in toll" :key="index">
          <div class="toll-image">
            <!--            <span class="cancel-toll-blk">sample</span>-->
            <img :src="data.image" @click="viewImage(index)" class="image-view"/>
          </div>

          <div class="toll-status-blk">
            <div class="toll-status-modal"
                 :class="{'approved-toll-status':data.status === 1,'wfa-toll-status':data.status === 0}">
<!--              <span v-if="data.status === 2" class="wfa-status-blk">Rejected - {{ data.message }}</span>-->
              <span v-if="data.status === 1" class="wfa-status-blk">Approved</span>
<!--              <span v-if="data.status === 0" class="wfa-status-blk">Waiting for Approval</span>-->
              <span class="amount-blk">₹ {{ data.amount }} </span>
              <!--              <i class="fas fa-times" v-if="data.status === 0"></i>-->
            </div>
          </div>
        </div>
      </div>
    </v-card>
    <vue-easy-lightbox
        :visible="visible"
        :imgs="images"
        :index="openIndex"
        @hide="hideImage"
    >

    </vue-easy-lightbox>
  </div>
</template>

<script>
export default {
  name: "TripTollView",
  props: {
    toll: Array
  },
  watch: {
    toll() {
      this.images = []
      this.visible = false
      this.openIndex = 0
    }
  },
  data: () => {
    return {
      images: [],
      openIndex: 0,
      visible: false,
    }
  },
  methods: {
    viewImage(index) {
      this.images = []
      this.toll.map(data => {
        this.images.push(data.image)
      })
      this.openIndex = (this.images.length > index ? index : 0);
      this.visible = true
    },
    hideImage() {
      this.visible = false
    }
  }
}
</script>

<style scoped lang="scss">
.trip-toll-modal {
  margin: 20px 0;

  .toll-card {
    display: flex;
    padding: 10px;
    border: 1px solid gray;
    flex-direction: column;
    border-radius: 0;

    .toll-header {
      padding: 5px;
      border-bottom: 1px solid #d4d4d4;
      color: #ef7b22;
      font-weight: bold;
      font-size: 18px;
    }

    .toll-list-content {
      display: flex;
      flex-wrap: wrap;
      margin: 10px 0;
      align-items: center;

      .toll-list {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        word-break: break-all;
        border: 1px solid gray;
        width: 31%;
        margin: 0 10px;

        .toll-image {
          width: 100%;
          padding: 10px;

          .image-view {
            width: 100%;
            height: 160px;
            object-fit: cover;
          }

          //.cancel-toll-blk {
          //  position: relative;
          //  top: 22px;
          //  left: 0;
          //  background-color: red;
          //  padding: 5px;
          //  width: 10%;
          //}
        }

        .toll-status-blk {

          color: white;
          font-size: 15px;
          font-weight: bold;
          border-top: 1px solid gray;
          display: flex;
          width: 100%;

          .toll-status-modal {
            background-color: #F44336;
            padding: 5px;
            margin: 5px;
            display: flex;
            align-items: center;
            border-radius: 2px;
            width: 100%;

            .wfa-status-blk {
              width: 85%;
            }

            .amount-blk {
              width: 15%;
              text-align: end;
            }
          }

          .approved-toll-status {
            background-color: #4CAF50;
          }

          .wfa-toll-status {
            background-color: #ff9800;
          }
        }


      }
    }
  }
}
</style>