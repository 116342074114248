<template>
 <div class="trip-status-block" :class="{ 'log-status': trip.tripLogs.length > 0 }">
  <div class="status-container">
   <!-- <v-chip small :color="trip.tripStatus.color">{{ trip.tripStatus.name }}</v-chip> -->
   <span class="status-block" :style="`color: ${trip.tripStatus.color};background-color:${hexToRgba(trip.tripStatus.color, 0.2)};border: 1px solid ${trip.tripStatus.color}`">{{
    trip.tripStatus.name
   }}</span>

   <v-btn x-small color="#0e64a8" class="map-btn" @click="openMap">View Map</v-btn>
  </div>
  <div class="status-container">
   <div class="time-info" v-for="(log, logIndex) in trip.tripLogs" :key="logIndex" :class="{ 'delay-info': !log.onTime }">
    <v-tooltip bottom v-if="log.status === 'driver'" color="#494a4a">
     <template v-slot:activator="{ on, attrs }">
      <i class="fas fa-taxi" v-bind="attrs" v-on="on"></i>
     </template>
     <span style="font-size: 12px;">Driver Arrived</span>
    </v-tooltip>

    <v-tooltip bottom v-if="log.status === 'user'" color="#3a3b3b">
     <template v-slot:activator="{ on, attrs }">
      <i class="fas fa-user-circle" v-bind="attrs" v-on="on"></i>
     </template>
     <span style="font-size: 12px">{{ log.name }} - {{ log.mobile }}</span>
    </v-tooltip>

    <v-tooltip bottom v-if="log.status === 'end'" color="#3a3b3b">
     <template v-slot:activator="{ on, attrs }">
      <i class="fas fa-building" v-bind="attrs" v-on="on"></i>
     </template>
     <span style="font-size: 12px;">Trip Ended</span>
    </v-tooltip>
    <span>{{ log.time }}</span>
   </div>
  </div>
 </div>
</template>
<script>
export default {
 name: "TripStatusLog",
 props: {
  trip: Object,
 },
 methods: {
  hexToRgba(hex, alpha) {
   hex = hex.replace("#", "");

   // Parse the hex values
   let r = parseInt(hex.substring(0, 2), 16);
   let g = parseInt(hex.substring(2, 4), 16);
   let b = parseInt(hex.substring(4, 6), 16);

   // Create the RGBA string
   let rgba = "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";

   return rgba;
  },
  openMap() {
   console.log(this.trip.user[0].liveMap);
   if (this.trip.user[0].liveMap) {
    window.open(this.trip.user[0].liveMap, "_blank");
   }
  },
 },
};
</script>
<style lang="scss">
.trip-status-block {
 @media screen and (max-width: 1320px) {
  font-size: 10px;
 }

 @media screen and (max-width: 977px) {
  font-size: 13px;
  width: 50%;
 }

 @media screen and (max-width: 800px) {
  width: 100%;
 }

 font-size: 12px;
 text-align: start;
 font-weight: 500;
 // font-weight: bold;
 width: 30%;
}

.trip-status-block {
 font-size: 12px;

 @media screen and (max-width: 1320px) {
  font-size: 11px;
 }

 @media screen and (max-width: 977px) {
  font-size: 13px;
 }

 @media screen and (max-width: 474px) {
  font-size: 12px;
 }

 //   display: flex;
 //   flex-direction: column;
 font-weight: 500;
 color: #3a77b4;
 // row-gap: 5px;

 .status-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media screen and (max-width: 1320px) {
   column-gap: 7px;
  }

  column-gap: 10px;

  .map-btn {
   color: white;
  }

  .status-block {
   // color: #ff9600;
   background-color: #ffe4bf;
   width: fit-content;
   text-transform: capitalize;
   padding: 3px 8px;
   border-radius: 10px;
   font-size: 10px;
  }

  .time-info {
   display: flex;
   flex-direction: column;
   align-items: center;
   color: green;
  }

  .delay-info {
   color: red;
  }

  .trip-type {
   color: rgb(28, 144, 162);
   background-color: rgba(28, 144, 162, 0.5);
  }
 }
}
</style>
