<template>
  <div class="main-outercont">
    <div class="common-tabouterblk">

      <v-tabs class="tab-headerblk" v-model="currentTab"  height="60px"  show-arrows background-color="#282828">
        <v-tab href='#tab-periodical-reports'>Periodical Reports</v-tab>
        <v-tab href='#tab-daily-reports'>Daily Reports</v-tab>
<!--        <v-tab href='#tab-employee-delay-report'>Employee Delay Reports</v-tab>-->
<!--        <v-tab href='#tab-driver-delay-report'>Driver Delay Reports</v-tab>-->
<!--        <v-tab href='#tab-count'>Trip Counts</v-tab>-->
<!--        <v-tab href='#tab-count-detailed'>Trip Count Detailed Report</v-tab>-->
<!--        <v-tab href="#tab-employee-booking-reports">Employee Booking Report</v-tab>-->
      </v-tabs>

      <v-tabs-items v-model="currentTab">
        <v-tab-item id="tab-periodical-reports" class="tab-outer" transition="fade-transition" reverse-transition="fade-transition">
          <PeriodicalReports></PeriodicalReports>
        </v-tab-item>
        <v-tab-item id="tab-daily-reports" class="tab-outer" transition="fade-transition" reverse-transition="fade-transition">
          <DailyReports></DailyReports>
        </v-tab-item>

<!--        <v-tab-item id="tab-employee-delay-report" class="tab-outer" transition="fade-transition" reverse-transition="fade-transition">-->
<!--          <EmployeeDelayReport></EmployeeDelayReport>-->
<!--        </v-tab-item>-->
<!--        <v-tab-item id="tab-driver-delay-report" class="tab-outer" transition="fade-transition" reverse-transition="fade-transition">-->
<!--          <DriverDelayReport></DriverDelayReport>-->
<!--        </v-tab-item>-->
<!--        <v-tab-item id="tab-count" class="tab-outer" transition="fade-transition" reverse-transition="fade-transition">-->
<!--          <TripCountReport></TripCountReport>-->
<!--        </v-tab-item>-->
<!--        <v-tab-item id="tab-count-detailed" class="tab-outer" transition="fade-transition" reverse-transition="fade-transition">-->
<!--          <TripSummaryReport></TripSummaryReport>-->
<!--        </v-tab-item>-->
<!--        <v-tab-item id="tab-employee-booking-reports">-->
<!--          <EmployeeBookingReport></EmployeeBookingReport>-->
<!--        </v-tab-item>-->

      </v-tabs-items>

    </div>

  </div>
</template>

<script>
import DailyReports from "./DailyReports";
import PeriodicalReports from "./PeriodicalReports";
import EmployeeDelayReport from "./EmployeeDelayReport";
import DriverDelayReport from "./DriverDelayReport";
import TripCountReport from "./TripCountReport";
import TripSummaryReport from "./TripSummaryReport";
import EmployeeBookingReport from "./EmployeeBookingReport";
export default {

  data() {
    return {
      currentTab: 'tab-periodical-reports',
      // currentTab: 'tab-count',
      user: undefined,
    }
  },
  components:{
    DailyReports,
    PeriodicalReports,
    EmployeeDelayReport,
    DriverDelayReport,
    TripCountReport,
    TripSummaryReport,
    EmployeeBookingReport,
  },
  name: "Reports",
  async mounted() {

  },
  methods: {

  },
  watch: {

  }

}
</script>

<style lang="scss" scoped>
.v-tabs {
  position: relative;
  z-index: 10;
  .v-item-group {
    .v-tab:not(.v-tab--active) {
      //color: red !important;
    }
  }
}
.v-item-group {
  position: relative;
  z-index: 0;
}
</style>