<template>
  <div class="report-content">
    <div class="filter-block">
      <div class="filter-items">
        <v-menu
            v-model="startDateModal"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="startDate"
                label="Date"
                readonly
                v-on="on"
                outlined
                dense
                hide-details
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="startDate"
              no-title
              @input="startDateModal = false"
              :max="startMax"
          ></v-date-picker>
        </v-menu>
      </div>


      <div class="filter-items">
        <v-autocomplete
            v-model="state"
            :items="states"
            label="State"
            placeholder="State"
            color="#ff9600"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            clearable
            @change="stateChanged"
        ></v-autocomplete>
      </div>

      <div class="filter-items">
        <v-autocomplete
            v-model="branch"
            :items="branches"
            placeholder="Branch"
            label="Branch"
            color="#ff9600"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            clearable
        ></v-autocomplete>
      </div>

      <div class="filter-items">
        <v-autocomplete
            v-model="department"
            :items="departments"
            label="Department"
            placeholder="Department"
            color="#ff9600"
            item-text="name"
            item-value="id"
            outlined
            dense
            hide-details
            clearable
        ></v-autocomplete>
      </div>

      <div class="filter-button">
        <v-btn color="#ff9600" title="Reset" light @click="reloadTrips" text><i class="fas fa-redo"></i></v-btn>
      </div>
    </div>

    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" md="12">
          <div class="report-content-column">
            <v-progress-circular
                v-if="loading"
                :width="2"
                :size="20"
                color="red"
                indeterminate
            ></v-progress-circular>

            <v-data-table
                :headers="headers"
                :items="formattedReportData"
                :items-per-page="options.itemsPerPage"
                class="elevation-1"
                :options.sync="options"
                :server-items-length="totalRows"
                :footer-props="{itemsPerPageOptions:options.itemsPerPageOptions}"
                fixed-header
                height="calc(100vh - 275px)"
                v-if="formattedReportData.length > 0"
            >

            </v-data-table>
            <div class="report-details-empty" v-else>
              <span>No data available.</span>
            </div>

            <div class="submit-block">
              <v-btn color="#034b26" :disabled="formattedReportData.length === 0" @click="exportReport">Export</v-btn>
              <v-alert
                  dense
                  type="info"
                  text
              >
                Only driver delayed trips will be listed here.
              </v-alert>
              <div class="submit-block-message" v-if="totalRows > 0">Total {{totalRows}} Trips</div>
              <div class="submit-block-message" v-else></div>
            </div>

          </div>
        </v-col>
      </v-row>
    </v-container>

<!--    <v-navigation-drawer-->
<!--        v-if="enableTripDetails"-->
<!--        v-model="tripDialog"-->
<!--        left-->
<!--        absolute-->
<!--        temporary-->
<!--        width="70%"-->
<!--        class="verification-drawer-modal"-->
<!--    >-->
<!--      <div class="payment-trip-block">-->
<!--        <TripDetails :tripId="selectedTrip" :hideButton="true"></TripDetails>-->
<!--      </div>-->
<!--    </v-navigation-drawer>-->

  </div>
</template>

<script>
// import TripDetails from "../trips/TripDetails";
import axios from "../../axios/axios-global";
import moment from "moment";
export default {
  name: 'DriverDelayReport',
  components: {
    // TripDetails,
  },
  data() {
    return {
      error: undefined,
      loading: false,

      state: 0,
      branch: 0,
      department: 0,
      states: [],
      allBranches: [],
      branches: [],
      formattedReportData: [],
      departments: [],

      reportDataTitles: {
        slNo: "SlNo",
        tripId: "TripId",
        date: "Date",
        scheduleTime: "Schedule Time",
        userName: "UserName",
        userNumber: "UserNumber",
        branch: "Branch",
        driverName: "DriverName",
        cabNumber: "CabNumber",
        driverArrivedtime: "Driver Arrived time",
        delayInMinutes: "Delay in Minutes",
      },

      today: moment().format("YYYY-MM-DD"),
      startMax: moment().format("YYYY-MM-DD"),
      endMin: "",
      startDateModal: false,
      startDate: moment().format("YYYY-MM-DD"),
      endDateModal: false,
      endDate: moment().format("YYYY-MM-DD"),

      headers: [],
      options: {
        page: 1,
        itemsPerPage: 1000,
        totalItems: 0,
        itemsPerPageOptions: [50, 100, 200, 500, 1000],
      },
      page: 1,

      selectedTrip: 0,

      enableTripDetails: false,
      tripDialog: false,
      totalRows: 0,


    }
  },
  async mounted() {
    await this.fetchStateBranchDepartments();
    await this.fetchDepartments();
    if(this.startDate !== '') {
      await this.fetchTrips();
    }
  },
  methods: {

    async fetchStateBranchDepartments() {
      axios.post('user/stateBranchList', { onlyAssigned: true })
          .then((response) => {
            if(response.data.stateList) {
              this.states = response.data.stateList;
            }
            if(response.data.branchList) {
              this.allBranches = response.data.branchList;
              this.branches = response.data.branchList;
            }
          })
          .catch((error) => {
            this.print(error);
          });
    },
    async fetchDepartments() {
      const response = await axios.get('settings/department/list')
      this.departments = response.data.department
    },

    async fetchTrips(page) {
      this.loading = true;
      this.totalRows = 0;
      page = (page !== undefined ? page : 1);
      const payload = {
        page: page,
        limit: this.options.itemsPerPage,
        startdate: this.startDate,
        enddate: this.startDate,
      };
      if(this.state > 0) {
        payload['state'] = this.state;
      }
      if(this.branch > 0) {
        payload['branch'] = this.branch;
      }
      if(this.department > 0) {
        payload['department'] = this.department
      }

      axios.post('reports/driver/delay', payload)
          .then((response) => {
            if(response.data.trips.length > 0) {
              this.prepareReportHeader();
              this.formatReportData(response.data.trips);
              this.totalRows = response.data.trips.length;
            } else {
              this.headers = [];
              this.formattedReportData = [];
            }
            this.loading = false;
          })
          .catch((error) => {
            this.print(error);
          });
    },
    formatReportData(rawData) {
      this.formattedReportData = [];
      if (rawData.length > 0) {
        this.counter = 1;
        for (let value of rawData) {
          let dataRow = {};
          dataRow = this.generateReportRow(value, this.counter);
          this.formattedReportData.push(dataRow);
          this.counter++;
        }
      } else {
        this.formattedReportData = [];
      }
    },

    filterTrips() {
      this.formattedReportData = [];
      this.fetchTrips(1);
    },

    generateReportRow(data, counter) {
      let row = {
        slNo: counter,
        tripId: data.id,
        date: this.convertDate(data.pickupdate, 'x', 'DD-MM-YYYY'),
        branch: data.branch,
        userName: data.userName,
        userNumber: data.userMobile,
        driverName: data.driver,
        cabNumber: data.vehicle,
        scheduleTime: this.convertDate(data.pickupdate, 'x', 'hh:mm A'),
        driverArrivedtime: (data.arriveddate !== 0 && data.arriveddate !== undefined ? this.convertDate(data.arriveddate, 'x', 'hh:mm A') : ''),
        // startTime: (data.startdate !== 0 && data.startdate !== undefined ? this.convertDate(data.startdate, 'x', 'hh:mm A') : ''),
        delayInMinutes: (data.arrivedDelay ? (data.arrivedDelay <= 0 ? 0 : data.arrivedDelay) : null),
      }
      return row;

    },

    prepareReportHeader() {
      this.headers = [];
      let titles = {};
      // this.headers = [{text: "#", value: 'check', sortable: false}];
      titles = this.reportDataTitles;
      for (let key in titles) {
        let headerObj = {text: titles[key], value: key, sortable: false,};
        this.headers.push(headerObj);
      }
    },

    openTrip(tripId) {
      if(tripId > 0) {
        this.enableTripDetails = true;
        setTimeout(() => {
          this.selectedTrip = tripId;
          this.tripDialog = true;
        },100)
      }
    },

    exportReport() {
      this.reportDialog = true;
      this.reportLoading = true;
      this.reportDialogMessage = "Report will be ready in few seconds...";
      let name = `Delay Report of ${this.startDate}`;
      if(this.endDate !== '') {
        name = `Delay Report of ${this.startDate} - ${this.endDate}`;
      }
      setTimeout(() => {
        const sheetTitles = this.reportDataTitles;
        // this.formattedReportData.unshift(sheetTitles);
        let reportData = [...this.formattedReportData];
        reportData.unshift(sheetTitles);
        this.$VueExcel.methods.downloadExcelSheet(reportData, name, {skipHeader: true});
        this.reportDialogMessage = "Report generated";
        this.reportDialog = false;
      }, 100)
    },

    stateChanged() {
      if(this.state > 0) {
        this.branches = this.allBranches.filter(item => item.stateId === this.state);
      } else {
        this.branches = this.allBranches;
        this.branch = 0;
      }
    },

    reloadTrips() {
      this.state = 0;
      this.branch = 0;
      this.department = 0;
      this.selectedTrip = 0;
      this.formattedReportData = [];
      this.startDate = moment().format("YYYY-MM-DD");
      this.endDate = moment().format("YYYY-MM-DD");

      this.stateChanged();

      setTimeout(() => {
        if(this.startDate !== '') {
          this.fetchTrips()
        }
      },100)
    },

  },
  computed: {

  },
  watch: {
    startDate(date) {
      if(date !== "") {
        this.fetchTrips()
      }
    },
    state() {
      this.fetchTrips();
    },
    branch() {
      this.fetchTrips();
      // if(value !== null && value !== undefined && value !== '') {
      //   this.fetchTrips();
      // }
    },
    department() {
      this.fetchTrips();
    }
  }
}
</script>

<style lang="scss" scoped>
.report-content {
  > .container {
    max-width: 100%;
    z-index: 10;
  }

  .v-data-table--fixed-header {
    &.remove-scrolling {
      ::v-deep .v-data-table__wrapper {
        overflow: hidden !important;
      }
    }
  }
}

.report-content {
  display: flex;
  flex-direction: column;

  .filter-block {
    padding: 10px 10px 0;
    display: flex;
    justify-content: center;

    .filter-items {
      padding: 0 10px;
      &.filter-overflow-item {
        max-width: 220px;
        max-height: 32px;
        overflow: hidden;
      }
    }

    .filter-button {
      .v-btn {
        ::v-deep span {
          font-size: 0.9rem;
          color: inherit;
        }
      }
    }
  }

  .submit-block {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0px 0;
    margin-top: 10px;
    border-top: 1px solid rgba(#000, 0.2);

    ::v-deep .v-btn {
      color: #FFF;
    }

    .report-summary-short {
      display: flex;

      .report-summary-content {
        display: flex;
        align-items: center;

        strong {
          padding: 5px 10px 5px 10px;
          font-size: 0.9rem;
          font-weight: 700;
          color: #d9056d;
          margin-right: 10px;
          border: 1px solid rgba(#000, 0.2);

          span {
            font-weight: 500;
            color: #4c4c4c;
            padding-right: 10px;
          }
        }
      }

      &.disabled-summary {
        opacity: 0.3;
      }
    }

    .report-actions {
      .v-select {
        ::v-deep .v-label.v-label--active {
          display: none;
        }

        .v-select__selections {
          min-height: 30px;
        }
      }
    }
  }

  .report-content-column {
    padding: 10px;
    /*background: #f3f3f3;*/
    border: 1px solid rgba(#870947, 0.5);
    position: relative;

    .submit-block {
      position: absolute;
      bottom: 20px;
      left: 20px;
      //width: 60%;
      width: calc(100% - 40px);
      border: 0 none;
      margin-top: 0;
      @media only screen and (min-width: 1200px) and (max-width: 1399px) {
        width: 67%;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 67%;
      }
      .submit-block-message {
        padding: 0 10px 0 0;
        font-size: 1.0rem;
        font-weight: 500;
        color: #440021;
        font-family: 'Muli', sans-serif;
      }
    }
  }

  .v-data-table.v-data-table--fixed-header {
    /*background: #ececec;*/
    ::v-deep .v-data-table__wrapper {
      table {
        border-collapse: separate;

        tr {
          th {
            font-size: 0.9rem;
            font-weight: 700;
            color: #d9056d;
            font-family: 'Muli', sans-serif;
            white-space: nowrap;
            border: 1px solid rgba(0, 0, 0, 0.2);
            z-index: 1;
            /*background: #ececec;*/
            text-align: center !important;
            &:first-child {
              padding: 7px 10px;

              //position:sticky;
              //left:0;
              //z-index:2;
              //background: #ddd;
              //text-align: center;
              //width: 60px;
            }

            &:nth-child(1) {
              //position: sticky;
              //left:61px;
              //left: 0px;
              z-index: 2;
              background: #FFF;
              text-align: center;
            }
            &:nth-child(2) {
              //position: sticky;
              //left:61px;
              //left: 65px;
              //left: 38px;
              z-index: 2;
              background: #ddd;
              text-align: center;
            }

            //&:last-child {
            //  position: sticky;
            //  right: 0;
            //  z-index: 2;
            //  //background: #ff9600;
            //  background: #cdcdcd;
            //  text-align: center;
            //
            //  span {
            //    width: 100%;
            //    text-align: center;
            //    display: inline-block;
            //  }
            //}
          }

          td {
            font-size: 0.85rem;
            font-weight: 500;
            color: #440021;
            font-family: 'Muli', sans-serif;
            padding: 7px 5px;
            white-space: nowrap;
            border: 1px solid rgba(0, 0, 0, 0.2);

            &:first-child {
              padding: 7px 10px;

              //position:sticky;
              //left:0;
              //z-index:1;
              //background: #ddd;
              //text-align: center;
              //width: 60px;
            }

            &:nth-child(1) {
              //position: sticky;
              //left:61px;
              //left: 0px;
              z-index: 1;
              background: #FFF;
              text-align: center;
            }
            &:nth-child(2) {
              //position: sticky;
              //left:61px;
              //left: 38px;
              z-index: 1;
              background: #ddd;
              text-align: center;
            }

            //&:last-child {
            //  position: sticky;
            //  right: 0;
            //  z-index: 1;
            //  //background: #ff9600;
            //  background: #cdcdcd;
            //}

            ::v-deep .v-btn {
              /*background: #737373 !important;*/
              /*color: #FFF !important;*/
              color: #d9056d;
              font-size: 0.75rem;
              font-weight: 700;
              text-transform: none;
            }

            div {
              white-space: pre;
              &.multiline-content {
                display: flex;
                flex-direction: column;
                .highlight-column {
                  margin-left: 5px;
                }
              }
            }

            &.lead-column {
              max-width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              div {
                display: inline-block;
                position: relative;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            &.adjust-width {
              max-width: 150px;

              span {
                //white-space: normal;

                display: inline-block;
                position: relative;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            &.status-column {
              div {
                white-space: pre;

                span:not(.v-tooltip) {
                  white-space: pre;
                  display: block;
                }
              }
              .action-wrap {
                display: flex;
                justify-content: space-around;
                .v-btn {
                  font-size: 1.4rem;
                  padding: 0 2px;
                  min-width: 32px;
                  &.download-btn {
                    font-size: 0.75rem;
                    padding: 5px 10px;
                  }
                }
                &.status-dispute {
                  padding-left: 5px;
                  > span {
                    padding: 5px 10px;
                    background: red;
                    color: #FFF;
                  }
                }
                &.status-verified {
                  padding-left: 5px;
                  > span {
                    padding: 5px 10px;
                    background: green;
                    color: #FFF;
                  }
                }
              }
            }


            &.trip-details-trigger-column {
              span {
                text-decoration: underline;
                cursor: pointer;
              }
            }

            //&.fixed-column {
            //    position: absolute;
            //    width: 120px;
            //    background: #FFF;
            //    top: 0;
            //    height: 100%;
            //    &.column-1 {
            //        left: 0;
            //    }
            //    &.column-2 {
            //        left: 120px;
            //    }
            //    &.column-3 {
            //        left: 240px;
            //    }
            //}
          }

          &.row-with-warning {
            td {
              background: #eecd7c;
              color: #000;
              //opacity: 0.4;
            }
          }

          &.row-with-errors {
            td {
              background: #e42e50;
              color: #FFF;
              //opacity: 0.4;
            }
          }
          &.highlighted-row {
            background: #e4de2e;
          }
        }
      }
    }

    ::v-deep .v-data-footer {
      justify-content: flex-end;
      margin-right: 0;
      padding: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  ::v-deep .v-alert {
    margin-bottom: 0;
  }

  .v-input {
    margin: 0;
    padding-top: 0;

    ::v-deep .v-input--selection-controls__input {
      margin: 0;

      .primary--text {
        color: #870947 !important;
        caret-color: #870947 !important;
      }
    }
  }

  .report-details-empty {
    width: 100%;
    height: calc(100vh - 220px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

}

.v-tooltip__content {
  &.lead-tooltip {
    white-space: pre;
  }

  &.comments-tip {
    white-space: pre;

    span {
      white-space: pre;
    }
  }
}



.verification-drawer-modal {
  background: #FFF;
  padding: 20px 20px;
  height: calc(100vh + -60px) !important;
  z-index: 150;
}

.report-generate-message {
  .v-card__title {
    &.text-center {
      display: flex;
      justify-content: center;
    }
  }
  .report-loader {
    height: 100px;
    position: relative;
    .v-progress-circular.v-progress-circular--indeterminate.red--text {
      margin-left: -25px;
      transform: translateY(-50%);
    }
  }
}

.v-tooltip__content {
  > span {
    .list-tooltip-content {
      display: flex;
      flex-direction: column;
      strong {
        color: #e4de2e;
        font-size: 0.9rem;
      }
    }
  }
}

</style>