<template>
    <div>
        <!-- <md-field>
            <label>{{placeholder}}</label>
            <md-input ref="autocomplete" :placeholder="placeholder"
                class="search-location"
                onfocus="value = ''"
                type="text"></md-input>
            </md-field> -->
       <!-- <input ref="autocomplete"
               :placeholder="placeholder"
               class="search-location"
               onfocus="value = ''"
               type="text"/>-->
      <!--  <v-autocomplete
                id="autocomplete"
                label="Location"
        >
        </v-autocomplete>-->

        <v-text-field
                v-if="validate"
                :id="`id${flag}`"
                :label="placeholder"
                placeholder="Location"
                v-model="content"
                @focus="$event.target.select()"
                dense
                outlined
        ></v-text-field>
<!--      :error-messages="addressErrors"-->
<!--      @input="$v.content.$touch()"-->
<!--      @blur="$v.content.$touch()"-->
        <v-text-field
                v-else
                :id="`id${flag}`"
                :label="placeholder"
                placeholder=""
                v-model="content"
                @focus="$event.target.select()"
        ></v-text-field>
    </div>
</template>

<script>
    import gmapsInit from '../Utils/gmpas'
    // import { validationMixin } from 'vuelidate'
    // import { required } from 'vuelidate/lib/validators'

    export default {
        name: "VuePlaces",
        props: {
            placeholder: String,
            flag: Number,
            value: String,
            callBack: Function,
            validate: Boolean,
            locationData: Object,
            addressType: String,
            placeStatus: Boolean,
        },
        data () {
            return {
                content: this.value,
                location: ((Object.keys(this.locationData).length > 0 && this.locationData !== undefined) ? this.locationData : {} ),
                searchType: (this.addressType !== '' ? this.addressType : 'geocode'),
            }
        },
      watch: {
        placeStatus() {
          this.content = ''
        }
      },
        // mixins: [validationMixin],
        // validations: {
        //     content: {required}
        // },
        // props:['flag','callBack','placeholder'],
        async mounted() {
//            const google = window.google;
            const google = await gmapsInit();
            const autocompleteInput = document.querySelector(`#id${this.flag}`);

            if(this.validate) {
                const self = this;
                if(autocompleteInput !== null && autocompleteInput !== undefined) {
                    autocompleteInput.addEventListener("change", function(){
                        self.content = '';
                        self.$emit('validate-parent', self.flag);
                    });
                }
            }

          const options = {
            fields: ["formatted_address", "geometry", "name", "vicinity"],
            strictBounds: false,
            types: [this.searchType, 'establishment'], componentRestrictions: {country: "in"}
          };

          this.autocomplete = new google.maps.places.Autocomplete(
              (autocompleteInput),
              options
          );

            this.autocomplete.addListener('place_changed', () => {
                let place = this.autocomplete.getPlace();
                let formatted_address;
                if(this.searchType === 'establishment') {
                    formatted_address = place.name + ', ' + place.formatted_address;
                } else {
                    formatted_address = place.name + ', ' + place.formatted_address;
                }
                let location = place.geometry.location;

                this.content = formatted_address;
                this.location = location;

                this.callBack({
                    flag: this.flag,
                    address: formatted_address,
                    location: location,
                    vicinity: place.vicinity
                })
            });
        },
        methods: {
            clear(){
                this.content =''
            }
        },
        // computed: {
        //     addressErrors () {
        //         const errors = []
        //         if (!this.$v.content.$dirty) return errors
        //         !this.$v.content.required && errors.push('Location is required.')
        //         !Object.keys(this.location).length > 0 && errors.push('Location is required.')
        //         return errors
        //     },
        // }

    }
</script>

<style scoped>

</style>