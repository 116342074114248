<template>
  <div class="map-container">
    <div id="map" class="map"></div>
    <div class="input-block">
      <!-- <v-text-field dense outlined label="Search Trip by ID, User, Driver"></v-text-field> -->
      <input type="text" placeholder="Search Trip by ID, Driver" v-model="searchText" @input="searchFilter" />
      <DriverSearchList v-if="searchText.length > 3" :searchList="searchList" @get-trip="showTripDetails"></DriverSearchList>
    </div>
    <div class="color-indicator">
      <v-list>
        <v-list-item v-for="(item, index) in filterDuplicate" :key="index">
          <v-list-item-icon>
            <div class="color-block" :style="{ backgroundColor: item.color }"></div>
          </v-list-item-icon>
          <v-list-item-content>{{ item.name }} </v-list-item-content>
        </v-list-item>
      </v-list>
    </div>

    <v-navigation-drawer v-model="drawer" absolute right width="500" temporary v-if="drawer">
      <DriverDrawer :tripDetails="tripDetails"></DriverDrawer>
    </v-navigation-drawer>
  </div>
</template>

<script>
import gmapsInit from "../../Utils/gmpas";
import DriverDrawer from "./DriverDrawer.vue";
import axios from "@/axios/axios-global";
import DriverSearchList from "./DriverSearchList.vue";
import io from "socket.io-client";
export default {
  name: "Map",
  components: { DriverDrawer, DriverSearchList },
  data() {
    return {
      map: null,
      infoWindow: [],
      markersData: [
        { lat: 12.9304, lng: 77.6784 }, // Example marker 1
        { lat: 12.935379, lng: 77.692635 }, // Example marker 2
        { lat: 12.939683, lng: 77.695621 }, // Example marker 3
      ],
      markers: [], // Store marker instances
      trafficLayer: null,
      trafficLayerVisible: true,
      driverList: [],
      searchText: "",
      searchList: [],
      drawer: false,
      tripDetails: {},
      branch: "",

      colorIndicators: [
        { name: "Un Assigned", color: "#d71010", status: 1, innercolor: "#f05555" },
        { name: "One the way", color: "#fc790d", status: 5, innercolor: "#FFA256" },
        { name: "Arrived", color: "#1262ad", status: 6, innercolor: "#4695DE" },
        { name: "Started", color: "#127B35", status: 7, innercolor: "#52DA7F" },
        { name: "Started", color: "#127B35", status: 8, innercolor: "#52DA7F" },
      ],

      socketDriver: null,

      currentLocations: [],
    };
  },
  async mounted() {
    const branch = localStorage.getItem("selectedBranch");
    this.branch = JSON.parse(branch);
    setTimeout(async () => {
      await this.getDriverList();
      await this.unAssignedDriver();
      await this.initMap();
      this.connectSocket();
    }, 500); // Adjust the delay as neede
  },
  methods: {
    showTripDetails(data) {
      this.openDriverDrawer(data);
      this.userToken = data.tripid;
    },
    async searchFilter() {
      const length = this.checkLetter(this.searchText) ? 2 : 4;
      if (this.searchText.length > length) {
        console.log(length);
        const payload = { search: this.searchText, branch: -1 };
        const response = await axios.post("map/driver/search", payload);
        if (response.status === 200) {
          this.searchList = response.data;
        } else {
          this.searchList = [];
        }
      }
    },
    checkLetter(str) {
      const regex = /[a-zA-Z]/;
      return regex.test(str);
    },
    async getDriverList() {
      const response = await axios.post("map/onTripDriverList", { branch: -1 });
      this.driverList = response.data;
      console.log(response.data, "response.data");
      //  this.driverList.push(...response.data);
      this.driverList.map((data) => {
        if ([1, 5, 6, 7, 8].includes(data.status)) {
          const color = data.status ? this.colorIndicators.find((color) => color.status === data.status).color : "";
          const innercolor = data.status ? this.colorIndicators.find((color) => color.status === data.status).innercolor : "";
          data["color"] = color;
          data["innercolor"] = innercolor;
        }
      });
    },
    async unAssignedDriver() {
      const response = await axios.post("map/unAssignedDriverList", { branch: -1 });
      this.driverList.push(...response.data);
      this.driverList.map((data) => {
        if ([1, 5, 6, 7, 8].includes(data.status)) {
          const color = data.status ? this.colorIndicators.find((color) => color.status === data.status).color : "";
          const innercolor = data.status ? this.colorIndicators.find((color) => color.status === data.status).innercolor : "";
          data["color"] = color;
          data["innercolor"] = innercolor;
        }
      });
    },
    hexToRgba(hex, alpha = 1) {
      // Remove the leading # if present
      hex = hex.replace(/^#/, "");

      // Parse r, g, b values
      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);

      // Return the rgba color string
      return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    },
    async initMap() {
      const mapElement = document.getElementById("map");

      const google = await gmapsInit();

      this.trafficLayer = new google.maps.TrafficLayer();

      // Initialize the map centered at a default location
      const defaultLocation = { lat: 12.9716, lng: 77.5946 }; // Bangalore, India
      this.map = new google.maps.Map(mapElement, {
        center: defaultLocation,
        zoom: 13,
      });

      this.addMarkers();

      if (this.map) this.trafficLayer.setMap(this.map);

      // Add listener to update map based on search results
      //    searchBox.addListener("places_changed", () => {
      //     const places = searchBox.getPlaces();
      //     if (places.length === 0) return;

      //     const place = places[0];
      //     const location = place.geometry.location;
      //     this.setLocation(location.lat(), location.lng());
      //     this.map.setCenter({ lat: location.lat(), lng: location.lng() });
      //    });
    },
    addMarkers() {
      this.driverList.map((position, index) => {
        // const existing = this.currentLocations.find((loc) => loc.lat === position.lat && loc.lng === position.lng);
        // if (!existing || existing.status !== position.status) {
        //  if (this.infoWindow) {
        //   this.infoWindow.close(); // Close the existing InfoWindow
        //  }
        const uniqueId = `content-click-${index}`;
        const infoWindowContent = `
    <div class="content-info" id="${uniqueId}">
        <div class="inner-content-container" style="background-color: ${this.hexToRgba(position.color, 0.8)};">
          <div class="inner-content-info" style="background-color: ${this.hexToRgba(position.innercolor, 1)};">
          </div>
        </div>
        <div class="beep-marker" style="background-color: ${this.hexToRgba(position.color, 0.8)};"></div>
    </div>
    `;
        const infoWindow = new google.maps.InfoWindow({
          content: infoWindowContent,
          position: position.location,
        });

        infoWindow.open(this.map);

        this.infoWindow.push(infoWindow);

        google.maps.event.addListenerOnce(infoWindow, "domready", () => {
          const button = document.getElementById(uniqueId);
          if (button) {
            button.addEventListener("click", () => {
              console.log("object");
              this.openDriverDrawer(position);
            });
          }
        });
        // }
      });
      //  this.currentLocations = [...this.driverList];
    },

    connectSocket() {
      const token = localStorage.getItem("vendorLoginToken");
      const vendor = localStorage.getItem("vendorId");

      let uri = "token=" + token + "&type=Sp" + "&vendor=" + vendor + "&branch=" + this.branch.id;
      this.socketDriver = io.connect("http://sp.taski.in/sp/map", {
        path: "/socketSp",
        query: encodeURI(uri),
      });

      this.socketDriver.on("connect", function () {
        console.log("connect web dashboard socketDriver");
      });
      this.socketDriver.on("tripDriverlocation", (data) => {
        this.getTripLocation(data);
      });
      this.socketDriver.on("driverlocation", (data) => {
        this.getTripLocation(data);
      });
    },
    getTripLocation(data) {
      const findIndex = this.driverList.findIndex((driver) => driver.token === data.token);
      if ([1, 5, 6, 7, 8].includes(data.status)) {
        if (findIndex !== -1) {
          this.driverList[findIndex].location = data.location;
          this.driverList[findIndex].status = data.status;
        } else {
          this.driverList.push(data);
        }
      } else {
        if (findIndex !== -1) {
          this.driverList.splice(findIndex, 1);
        } else {
          this.driverList.push(data);
        }
      }
      this.clearInfoWindows();

      this.driverList.map((data) => {
        if ([1, 5, 6, 7, 8].includes(data.status)) {
          const color = data.status ? this.colorIndicators.find((color) => color.status === data.status).color : "";
          const innercolor = data.status ? this.colorIndicators.find((color) => color.status === data.status).innercolor : "";
          data["color"] = color;
          data["innercolor"] = innercolor;
        }
      });
      setTimeout(() => {
        this.addMarkers();
      });
    },

    clearInfoWindows() {
      this.infoWindow.forEach((window) => {
        window.close();
      });
      this.infoWindow = []; // Clear the array of InfoWindows
    },
    openDriverDrawer(details) {
      this.drawer = true;
      this.tripDetails = details;
    },
  },
  computed: {
    filterDuplicate() {
      return this.colorIndicators.filter((indicator, index, self) => self.findIndex((item) => item.name === indicator.name) === index);
    },
  },
};
</script>

<style lang="scss" scoped>
.map-container {
  height: calc(100vh - 60px);
  position: relative;
  overflow: hidden;
}

.map {
  width: 100%;
  height: 100%; /* Ensure map takes full height */
}

.input-block {
  position: absolute;
  top: 10px;
  right: 59px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  input {
    width: 280px;
    background: #f9f9f9;
    padding: 9px 12px;
    outline: none;
    border: 1px solid rgb(198, 198, 198);
    border-radius: 5px;
    font-size: 14px;
    transition: all 0.3s ease;

    &:focus {
      border-color: #007bff;
      box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    }

    &::placeholder {
      color: #aaa;
      font-style: italic;
    }
  }
}

.color-block {
  width: 15px;
  height: 15px;
  border-radius: 3px;
}

.color-indicator {
  background: #fff;
  position: absolute;
  top: 90px;
  padding: 5px;
  left: 10px;
  box-shadow: 0 3px 6px rgba(25, 32, 36, 0.16), 0 -1px 4px rgba(25, 32, 36, 0.04);
  z-index: 1000;
  width: 180px;
  border-radius: 2px;
  .v-list-item__icon {
    margin: 10px 0;
  }
  .v-list-item {
    min-height: 39px;
  }
  .v-list-item__content {
    font-size: 12px;
    padding: 0;
    margin-bottom: 2px;
  }
}

::v-deep .content-info {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 70px;
  position: relative;
  border-radius: 50%;
  cursor: pointer;
  .inner-content-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    // background-color: rgba(252, 121, 13, 81%);
    border-radius: 50%;
    .inner-content-info {
      width: 8px;
      height: 8px;
      //  background: #ffa256;
      border-radius: 50%;
    }
  }
  .beep-marker {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 50%;
    left: 35px;
    display: flex;
    justify-content: center;
    // right: 0;
    // background-color: rgba(255, 115, 0);
    opacity: 53%;
    border-radius: 50%;
    animation: pulse 1s infinite;
    transform: translate(-50%, -50%);
  }

  @keyframes pulse {
    0% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
    50% {
      transform: translate(-50%, -50%) scale(1.5);
      opacity: 0.5;
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
      opacity: 0;
    }
  }
}

::v-deep .gm-style-iw-tc {
  display: none;
  pointer-events: auto;
}

.map-button-block {
  position: absolute;
  bottom: 50px;
  left: 10px;
  background: #c4fbe8;
  padding: 8px;
  box-shadow: rgba(0, 0, 0, 0.13) 0px 2px 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  border: 3px solid #fff;
  cursor: pointer;
  border-radius: 5px;
  span {
    text-transform: uppercase;
    font-weight: bold;
    color: #000;
  }
}
.show-b-color {
  background: #c4fbe8;
  border: 3px solid #4ecca0;
}

::v-deep .gm-style-iw-c {
  background: none !important;
  box-shadow: none !important;
  padding: 0;
}

::v-deep .gm-style-iw-d {
  overflow: hidden !important;
}
::v-deep .gm-style-iw-tc {
  display: none !important;
}

::v-deep .gm-style-iw-chr {
  display: none !important;
}
</style>
