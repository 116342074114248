<template>
  <div class="report-content">
    <div class="filter-block">
      <div class="filter-items">
        <v-menu
            v-model="startDateModal"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
        >
          <template v-slot:activator="{ on }">
            <v-text-field
                v-model="startDate"
                label="Date"
                readonly
                v-on="on"
                outlined
                dense
                hide-details
            ></v-text-field>
          </template>
          <v-date-picker
              v-model="startDate"
              no-title
              @input="startDateModal = false"
              :max="startMax"
          ></v-date-picker>
        </v-menu>
      </div>

      <div class="filter-button">
        <v-btn color="#ff9600" title="Reset" light @click="reloadTrips" text><i class="fas fa-redo"></i></v-btn>
      </div>
    </div>

    <v-container fluid>
      <v-row no-gutters>
        <v-col cols="12" md="12">
          <div class="report-content-column">
            <v-progress-circular
                v-if="loading"
                :width="2"
                :size="20"
                color="red"
                indeterminate
            ></v-progress-circular>

            <v-data-table
                :headers="headers"
                :items="formattedReportData"
                :items-per-page="options.itemsPerPage"
                class="elevation-1"
                :options.sync="options"
                :server-items-length="totalRows"
                :footer-props="{itemsPerPageOptions:options.itemsPerPageOptions}"
                fixed-header
                height="calc(100vh - 275px)"
                v-if="formattedReportData.length > 0"
            >
              <template v-slot:body="{}">
                <tbody>

                <tr
                    v-for="(report, index) in formattedReportData"
                    :key="index"
                >
                  <td class="text-center"><span>{{ report.counter }}</span></td>
                  <td class="text-center fixed-column column-2 trip-details-trigger-column" @click="openTrip(report.id)"><span>{{ report.id }}</span></td>
                  <td class="text-center"><span>{{ report.date }}</span></td>
                  <td class="text-center"><span>{{ report.branch }}</span></td>
                  <td class="text-center"><span>{{ report.employee }}</span></td>
                  <td class="text-center"><span>{{ report.employeeId }}</span></td>
<!--                  <td class="text-center"><span>{{ report.mobileNo }}</span></td>-->
                  <td class="text-center"><span>{{ report.employeeEmail }}</span></td>
                  <td class="text-center"><span>{{ report.reportingManager }}</span></td>
                  <td class="text-center"><span>{{ report.reportingManagerEmail }}</span></td>
                  <td class="text-center adjust-width">
                    <v-tooltip bottom color="#440021">
                      <template v-slot:activator="{ on, attrs }">
                        <span
                            v-bind="attrs"
                            v-on="on"
                        >{{ report.startLocation }}</span>
                      </template>
                      <span>{{ report.startLocation }}</span>
                    </v-tooltip>
                  </td>
                  <td class="text-center"><span>{{ report.scheduleTime }}</span></td>
                  <td class="text-center"><span>{{ report.startTime }}</span></td>
                  <td class="text-center"><span>{{ report.endTime }}</span></td>
                  <td class="text-center"><span class="image-modal-trigger" @click="showMultiple(0, report.otherData)">{{ report.startOdometerReading }}</span></td>
                  <td class="text-center"><span class="image-modal-trigger" @click="showMultiple(1, report.otherData)">{{ report.endOdometerReading }}</span></td>
                  <td class="text-center"><span>{{ report.travelKM }}</span></td>
                  <td class="text-center"><span>{{ report.travelTime }}</span></td>
                  <td class="text-center"><span>{{ report.billingAmount }}</span></td>
                  <td class="text-center"><span>{{ report.status }}</span></td>
                </tr>

                </tbody>
              </template>
            </v-data-table>
            <div class="report-details-empty" v-else>
              <span>No data available.</span>
            </div>

            <div class="submit-block">
              <v-btn color="#034b26" :disabled="formattedReportData.length === 0" @click="exportReport">Export</v-btn>
              <v-alert
                  dense
                  type="info"
                  text
              >
                All approved trips will be listed here.
              </v-alert>
              <div class="submit-block-message" v-if="totalRows > 0">Total {{totalRows}} Trips</div>
              <div class="submit-block-message" v-else></div>
            </div>

          </div>
        </v-col>
      </v-row>
    </v-container>

    <vue-easy-lightbox
        moveDisabled
        :visible="visible"
        :imgs="imgs"
        :index="openIndex"
        @hide="handleHide"
    ></vue-easy-lightbox>

<!--    <v-navigation-drawer-->
<!--        v-if="enableTripDetails"-->
<!--        v-model="tripDialog"-->
<!--        left-->
<!--        absolute-->
<!--        temporary-->
<!--        width="70%"-->
<!--        class="verification-drawer-modal"-->
<!--    >-->
<!--      <div class="payment-trip-block">-->
<!--        <TripDetails :tripId="selectedTrip" :hideButton="true"></TripDetails>-->
<!--      </div>-->
<!--    </v-navigation-drawer>-->

    <v-dialog
        v-model="reportDialog"
        max-width="450px"
        justify="center"
        content-class="report-generate-message"
    >
      <v-card justify="center">
        <v-card-title class="text-center centered-title">Report Status</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <div>
            <h4 v-if="reportDialogMessage !== ''">Report will be ready shortly. </h4>
            <h4 v-else>{{reportDialogMessage}}</h4>

            <div class="report-loader" v-if="reportLoading && reportDialogMessage !== ''">
              <v-progress-circular
                  :width="5"
                  :size="50"
                  color="red"
                  indeterminate
              ></v-progress-circular>
            </div>

            <p v-if="reportDialogMessage !== ''">Please contact taSki if there are issues to generate reports.</p>

          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" text @click="reportDialog = false">Close</v-btn>
        </v-card-actions>
      </v-card>


    </v-dialog>

  </div>
</template>

<script>
// import TripDetails from "../trips/TripDetails";
import axios from "../../axios/axios-global";
import moment from "moment";
import VueEasyLightbox from 'vue-easy-lightbox';
export default {
  name: 'DailyReports',
  components: {
    // TripDetails,
    VueEasyLightbox,
  },
  data() {
    return {
      error: undefined,
      loading: false,

      trips: [],
      reportData: [],
      formattedReportData: [],

      reportDataTitles: {
        counter: "#",
        id: "Trip id",
        date: "Trip Date",
        branch: "Branch",
        employee: "Employee",
        employeeId: "TNL ID",
        employeeEmail: "Employee email ID",
        reportingManager: "Reporting Manager",
        reportingManagerEmail: "Reporting Manager Email",
        startLocation: "Employee Start Location",
        // endLocation: "Employee Reached Location",
        scheduleTime: "Schedule time",
        startTime: "Boarded time",
        endTime: "Deboarded time",
        startOdometerReading: "Starting KM",
        endOdometerReading: "Ending KM",
        travelKM: "Travel KM",
        travelTime: "Travel Time",
        billingAmount: "Billing Amount",
        status: "Status",
      },
      cancelledReportDataTitles: {
        rideId: "Ride Id",
        branch: "City",
        state: "State",
        date: "Trip Date",
        employeeName: "Employee Name",
        employeeId: "TNL ID",
        employeeEmail: "Employee Email ID",
        mobileNo: "Mobile No",
        department: "Department",
        driverName: "Driver Name",
        cabNumber: "Vehicle Number",
        cancelledDate: "Trip Cancelled  Date",
        package: "CancellationMinimum Charge - 4 Hr 40 KM",
      },

      today: moment().format("YYYY-MM-DD"),
      startMax: moment().format("YYYY-MM-DD"),
      endMin: "",
      startDateModal: false,
      startDate: moment().format("YYYY-MM-DD").valueOf(),
      endDateModal: false,
      endDate: moment().format("YYYY-MM-DD"),

      statusArray: [
        {id: 1, "name": "Completed"},
        {id: 2, "name": "Cancelled"},
      ],
      defaultStatus: 0,

      headers: [],
      options: {
        page: 1,
        itemsPerPage: 1000,
        totalItems: 0,
        itemsPerPageOptions: [50, 100, 200, 500, 1000],
      },
      page: 1,

      selectedTrip: 0,

      enableTripDetails: false,
      tripDialog: false,
      totalRows: 0,

      reportDialog: false,
      reportLoading: false,
      reportDialogMessage: "",

      imgs: [],
      visible: false,
      openIndex: 0,
      now: moment().valueOf(),

    }
  },
  async mounted() {
    if(this.startDate !== '') {
      await this.fetchTrips();
    }
  },
  methods: {
    async fetchTrips(page) {
      this.loading = true;
      this.totalRows = 0;
      page = (page !== undefined ? page : 1);
      const payload = {
        page: page,
        limit: this.options.itemsPerPage,
        startdate: this.startDate,
        enddate: this.startDate,
        workflow: 2,
      };
      let apiURL = 'report/daily';
      // if(this.defaultStatus === 2) {
      //   apiURL = 'trip/verification/list/cancel';
      // }
      axios.post(apiURL, payload)
          .then((response) => {
            this.trips = response.data;
            if(response.data.length > 0) {
              this.prepareReportHeader();
              this.formatReportData(response.data);
            } else {
              this.headers = [];
              this.formattedReportData = [];
            }
            this.page = response.data.page;
            this.totalRows = (response.data.rowCount ? response.data.rowCount : this.trips.length);
            this.selectedIds = [];
            this.loading = false;
          })
          .catch((error) => {
            this.print(error);
          });
    },
    formatReportData(rawData) {
      this.formattedReportData = [];
      if (rawData.length > 0) {
        this.counter = 1;
        for (let value of rawData) {
          let dataRow = {};
          if(this.defaultStatus === 2) {
            dataRow = this.generateCancelledReportRow(value, this.counter);
          } else {
            dataRow = this.generateReportRow(value, this.counter);
          }
          this.formattedReportData.push(dataRow);
          this.counter++;
        }
      } else {
        this.formattedReportData = [];
      }
    },

    filterTrips() {
      this.formattedReportData = [];
      this.fetchTrips(1);
    },

    generateReportRow(data, counter) {
      let billingAmount = (data.subtotal ? parseFloat(data.subtotal) : 0);
      if(data.toll && parseFloat(data.toll) > 0) {
        billingAmount = billingAmount + parseFloat(data.toll);
      }
      if(data.parking && parseFloat(data.parking) > 0) {
        billingAmount = billingAmount + parseFloat(data.parking);
      }
      if(data.permit && parseFloat(data.permit) > 0) {
        billingAmount = billingAmount + parseFloat(data.permit);
      }
      let row = {
        counter: counter,
        id: data.tripid,
        date: this.convertDate(data.pickupdate, 'x', 'DD-MM-YYYY'),
        branch: data.branch,
        employee: data.name,
        employeeId: data.empcode,
        employeeEmail: data.email,
        reportingManager: data.reportingmanager ? data.reportingManager : "-",
        reportingManagerEmail: data.reportingmanagermobile ? data.reportingmanagermobile : "-",
        startLocation:data.startlocation ? data.startlocation : "",
        // endLocation: (data.end ? data.end.address : ''),
        scheduleTime: this.convertDate(data.pickupdate, 'x', 'hh:mm A'),
        startTime: (data.start ? this.convertDate(data.start.date, 'x', 'hh:mm A') : ''),
        endTime: (data.end ? this.convertDate(data.end.date, 'x', 'hh:mm A') : ''),
        startOdometerReading: (data.start ? data.start.reading : null),
        endOdometerReading: (data.end ? data.end.reading : null),
        travelKM: (data.totalkm ? parseFloat(data.totalkm) : null),
        travelTime: (data.totaltime ? this.getHoursMinutes(data.totaltime) : null),
        billingAmount: billingAmount,
        status: data.status,
        otherData: data,
      }
      return row;

    },

    generateCancelledReportRow(data) {

      let row = {
        rideId: data.id,
        city: (data.branch.name !== undefined ? data.branch.name : ""),
        state: (data.state.name !== undefined ? data.state.name : ""),
        date: this.convertDate(data.pickupdate,'x','DD-MM-YYYY hh:mm A'),
        employeeName: (data.user.name !== undefined ? data.user.name : ""),
        employeeId: (data.user.empcode !== undefined ? data.user.empcode : ""),
        employeeEmail: (data.user.officialemail !== undefined ? data.user.officialemail : ""),
        mobileNo: (data.user.mobile !== undefined ? data.user.mobile : ""),
        department: (data.user.department !== undefined ? data.user.department : ""),
        driverName: (data.driver !== undefined ? data.driver.name : ""),
        cabNumber: (data.vehicle  !== undefined ? data.vehicle.vehicleno : ""),
        cancelledDate: this.convertDate(data.canceldate,'x','DD-MM-YYYY hh:mm A'),
        package: data.cancellationCharge,
        otherDetails: data,
      }

      return row;
    },

    prepareReportHeader() {
      this.headers = [];
      let titles = {};
      // this.headers = [{text: "#", value: 'check', sortable: false}];
      if(this.defaultStatus === 2) {
        titles = this.cancelledReportDataTitles;
      } else {
        titles = this.reportDataTitles;
      }
      for (let key in titles) {
        let headerObj = {text: titles[key], value: key, sortable: false,};
        this.headers.push(headerObj);
      }
    },

    openTrip(tripId) {
      if(tripId > 0) {
        this.enableTripDetails = true;
        setTimeout(() => {
          this.selectedTrip = tripId;
          this.tripDialog = true;
        },100)
      }
    },

    exportReport() {
      this.reportDialog = true;
      this.reportLoading = true;
      this.reportDialogMessage = "Report will be ready in few seconds...";
      let name = `Report of ${this.startDate}`;
      if(this.endDate !== '') {
        name = `Report of ${this.startDate} - ${this.endDate}`;
      }
      setTimeout(() => {
        const sheetTitles = this.reportDataTitles;
        // this.formattedReportData.unshift(sheetTitles);
        let reportData = [...this.formattedReportData];
        reportData.unshift(sheetTitles);
        this.$VueExcel.methods.downloadExcelSheet(reportData, name, {skipHeader: true});
        this.reportDialogMessage = "Report generated";
        this.reportDialog = false;
      }, 1000)
    },

    reloadTrips() {
      this.trips = [];
      this.reportData = [];
      this.selectedTrip = 0;
      this.formattedReportData = [];

      this.startDate = moment().format("YYYY-MM-DD");
      this.defaultServicePartner = 0;

      setTimeout(() => {
        this.fetchTrips()
      },100)
    },

    showMultiple(index, details) {
      this.now = moment().valueOf();
      if(details && details.start && details.start.photo) {
        const userStartReading = {
          src: details.start.photo + "?ver=" + this.now,
          title: `Start Reading: ${details.start.reading}`
        }
        this.imgs.push(userStartReading);
      }
      if(details && details.end && details.end.photo) {
        const userEndReading = {
          src: details.end.photo + "?ver=" + this.now,
          title: `End Reading: ${details.end.reading}`
        }
        this.imgs.push(userEndReading);
      }
      this.openIndex = (this.imgs.length > index ? index : 0);
      this.show()
    },
    handleHide() {
      this.imgs = [];
      this.visible = false
    },
    show() {
      this.visible = true
    },


  },
  computed: {

  },
  watch: {
    startDate(date) {
      if(date !== "") {
        this.fetchTrips()
      }
    },
  }
}
</script>

<style lang="scss" scoped>

//@media only screen and (min-width:992px) {
//
//  * {
//    scrollbar-width: thin;
//    scrollbar-color: #d4d4d4 #f7f7f7;
//  }
//
//  /* Works on Chrome/Edge/Safari */
//  *::-webkit-scrollbar {
//    width: 10px;
//  }
//  *::-webkit-scrollbar-track {
//    background: #f7f7f7;
//    width: 10px;
//  }
//  *::-webkit-scrollbar-thumb {
//    width: 10px;
//    background-color: #6d6d6d;
//  }
//
//  *::-webkit-scrollbar:horizontal{
//    height: 10px;
//    background-color: #f7f7f7;
//  }
//  *::-webkit-scrollbar-thumb:horizontal{
//    background: #6d6d6d;
//    border-radius: 0;
//  }
//  html,
//  body {
//    &::-webkit-scrollbar {
//      display: none;
//    }
//  }
//
//}

.report-content {
  > .container {
    max-width: 100%;
    z-index: 10;
  }

  .v-data-table--fixed-header {

    &.remove-scrolling {
      ::v-deep .v-data-table__wrapper {
        overflow: hidden !important;
      }
    }
  }
}

.report-content {
  display: flex;
  flex-direction: column;

  .filter-block {
    padding: 10px 10px 0;
    display: flex;
    justify-content: center;

    .filter-items {
      padding: 0 10px;
      &.filter-overflow-item {
        max-width: 220px;
        max-height: 32px;
        overflow: hidden;
      }
    }

    .filter-button {
      .v-btn {
        ::v-deep span {
          font-size: 0.9rem;
          color: inherit;
        }
      }
    }
  }

  .submit-block {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 0px 0;
    margin-top: 10px;
    border-top: 1px solid rgba(#000, 0.2);

    ::v-deep .v-btn {
      color: #FFF;
    }

    ::v-deep .v-alert {
      margin-bottom: 0;
    }

    .report-summary-short {
      display: flex;

      .report-summary-content {
        display: flex;
        align-items: center;

        strong {
          padding: 5px 10px 5px 10px;
          font-size: 0.9rem;
          font-weight: 700;
          color: #d9056d;
          margin-right: 10px;
          border: 1px solid rgba(#000, 0.2);

          span {
            font-weight: 500;
            color: #4c4c4c;
            padding-right: 10px;
          }
        }
      }

      &.disabled-summary {
        opacity: 0.3;
      }
    }

    .report-actions {
      .v-select {
        ::v-deep .v-label.v-label--active {
          display: none;
        }

        .v-select__selections {
          min-height: 30px;
        }
      }
    }
  }

  .report-content-column {
    padding: 10px;
    /*background: #f3f3f3;*/
    // border: 1px solid rgba(#870947, 0.5);
    position: relative;

    .submit-block {
      position: absolute;
      bottom: 20px;
      left: 20px;
      //width: 60%;
      width: calc(100% - 40px);
      border: 0 none;
      margin-top: 0;
      @media only screen and (min-width: 1200px) and (max-width: 1399px) {
        width: 67%;
      }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        width: 67%;
      }
      .submit-block-message {
        padding: 0 10px 0 0;
        font-size: 1.0rem;
        font-weight: 500;
        color: #440021;
        font-family: 'Muli', sans-serif;
      }
    }
  }

  .v-data-table.v-data-table--fixed-header {
    /*background: #ececec;*/
    ::v-deep .v-data-table__wrapper {
      table {
        border-collapse: separate;

        tr {
          th {
            font-size: 0.9rem;
            font-weight: 700;
            color: #d9056d;
            font-family: 'Muli', sans-serif;
            white-space: nowrap;
            border: 1px solid rgba(0, 0, 0, 0.2);
            z-index: 1;
            /*background: #ececec;*/
            text-align: center !important;
            &:first-child {
              padding: 7px 10px;

              //position:sticky;
              //left:0;
              //z-index:2;
              //background: #ddd;
              //text-align: center;
              //width: 60px;
            }

            &:nth-child(1) {
              //position: sticky;
              //left:61px;
              //left: 0px;
              z-index: 2;
              background: #FFF;
              text-align: center;
            }
            &:nth-child(2) {
              //position: sticky;
              //left:61px;
              //left: 65px;
              //left: 38px;
              z-index: 2;
              background: #ddd;
              text-align: center;
            }

            //&:last-child {
            //  position: sticky;
            //  right: 0;
            //  z-index: 2;
            //  //background: #ff9600;
            //  background: #cdcdcd;
            //  text-align: center;
            //
            //  span {
            //    width: 100%;
            //    text-align: center;
            //    display: inline-block;
            //  }
            //}
          }

          td {
            font-size: 0.85rem;
            font-weight: 500;
            color: #440021;
            font-family: 'Muli', sans-serif;
            padding: 7px 5px;
            white-space: nowrap;
            border: 1px solid rgba(0, 0, 0, 0.2);

            &:first-child {
              padding: 7px 10px;

              //position:sticky;
              //left:0;
              //z-index:1;
              //background: #ddd;
              //text-align: center;
              //width: 60px;
            }

            &:nth-child(1) {
              //position: sticky;
              //left:61px;
              //left: 0px;
              z-index: 1;
              background: #FFF;
              text-align: center;
            }
            &:nth-child(2) {
              //position: sticky;
              //left:61px;
              //left: 38px;
              z-index: 1;
              background: #ddd;
              text-align: center;
            }

            //&:last-child {
            //  position: sticky;
            //  right: 0;
            //  z-index: 1;
            //  //background: #ff9600;
            //  background: #cdcdcd;
            //}

            ::v-deep .v-btn {
              /*background: #737373 !important;*/
              /*color: #FFF !important;*/
              color: #d9056d;
              font-size: 0.75rem;
              font-weight: 700;
              text-transform: none;
            }

            div {
              white-space: pre;
            }

            .image-modal-trigger {
              text-decoration: underline;
              color: #0037ff;
              cursor: pointer;
            }

            &.lead-column {
              max-width: 150px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              div {
                display: inline-block;
                position: relative;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            &.adjust-width {
              max-width: 150px;

              span {
                //white-space: normal;

                display: inline-block;
                position: relative;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            &.status-column {
              div {
                white-space: pre;

                span:not(.v-tooltip) {
                  white-space: pre;
                  display: block;
                }
              }
              .action-wrap {
                display: flex;
                justify-content: space-around;
                .v-btn {
                  font-size: 1.4rem;
                  padding: 0 2px;
                  min-width: 32px;
                  &.download-btn {
                    font-size: 0.75rem;
                    padding: 5px 10px;
                  }
                }
                &.status-dispute {
                  padding-left: 5px;
                  > span {
                    padding: 5px 10px;
                    background: red;
                    color: #FFF;
                  }
                }
                &.status-verified {
                  padding-left: 5px;
                  > span {
                    padding: 5px 10px;
                    background: green;
                    color: #FFF;
                  }
                }
              }
            }


            &.trip-details-trigger-column {
              span {
                text-decoration: underline;
                cursor: pointer;
              }
            }

            //&.fixed-column {
            //    position: absolute;
            //    width: 120px;
            //    background: #FFF;
            //    top: 0;
            //    height: 100%;
            //    &.column-1 {
            //        left: 0;
            //    }
            //    &.column-2 {
            //        left: 120px;
            //    }
            //    &.column-3 {
            //        left: 240px;
            //    }
            //}
          }

          &.row-with-warning {
            td {
              background: #eecd7c;
              color: #000;
              //opacity: 0.4;
            }
          }

          &.row-with-errors {
            td {
              background: #e42e50;
              color: #FFF;
              //opacity: 0.4;
            }
          }
          &.highlighted-row {
            background: #e4de2e;
          }
        }
      }
    }

    ::v-deep .v-data-footer {
      justify-content: flex-end;
      margin-right: 0;
      padding: 0;
      opacity: 0;
      visibility: hidden;
    }
  }

  .v-input {
    margin: 0;
    padding-top: 0;

    ::v-deep .v-input--selection-controls__input {
      margin: 0;

      .primary--text {
        color: #870947 !important;
        caret-color: #870947 !important;
      }
    }
  }

  .report-details-empty {
    width: 100%;
    height: calc(100vh - 190px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }

}

.v-tooltip__content {
  &.lead-tooltip {
    white-space: pre;
  }

  &.comments-tip {
    white-space: pre;

    span {
      white-space: pre;
    }
  }
}



.verification-drawer-modal {
  background: #FFF;
  padding: 20px 20px;
  height: calc(100vh + -60px) !important;
  z-index: 150;
}

.report-generate-message {
  .v-card__title {
    &.text-center {
      display: flex;
      justify-content: center;
    }
  }
  .report-loader {
    height: 100px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

::v-deep .vel-img-modal {
  .vel-btns-wrapper {
    .btn__prev {
      left: 70px;
    }
    .btn__next {
      right: 70px;
    }
  }
}


</style>