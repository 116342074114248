<template>
 <div class="driver-list">
  <v-data-table :headers="headers" :items="getRemarks" :items-per-page="-1"  hide-default-footer>
   <template v-slot:item.status="{ item }">
    <v-chip :color="getRemarksStatusColor(item.action)" small dark>{{ getRemarksStatus(item.action) }}</v-chip>
   </template>
  </v-data-table>
 </div>
</template>

<script>
import moment from "moment";
import axios from "@/axios/axios-global";
export default {
 name: "DriversRemarksList",
 props: {
  token: String,
 },
 data: () => {
  return {
   headers: [
    { text: "Employee Name", align: "start", sortable: true, value: "remarksBy.name" },
    { text: "Reason", value: "reason" },
    { text: "Date", value: "date" },
    { text: "Status", value: "status", sortable: true },
   ],

   driverDetails: {},
  };
 },
 mounted() {
  this.getDriverDetails();
 },
 methods: {
  async getDriverDetails() {
   const payload = {
    driverId: this.token,
   };
   const response = await axios.post("driver/details", payload);
   console.log(response);
   this.driverDetails = response.data;
  },
  getRemarksStatus(status) {
   if (status === 0) {
    return "Acivated";
   } else if (status === 1) {
    return "Deactivated";
   } else if (status === 2) {
    return "Rejected";
   } else {
    return "Verified";
   }
  },
  getRemarksStatusColor(status) {
   if (status === 0) {
    return "#0DA300";
   } else if (status === 1) {
    return "red";
   } else if (status === 2) {
    return "#EB6746";
   } else {
    return "green";
   }
  },
 },
 computed: {
  getRemarks() {
   if (this.driverDetails && this.driverDetails.reason && this.driverDetails.reason.length > 0) {
    this.driverDetails.reason.map((remark) => {
     remark.date = remark.date ? moment(remark.date).format("YYYY-MM-DD HH:mm:ss") : "Not found";
     return remark;
    });
    return this.driverDetails.reason;
   } else {
    return [];
   }
  },
 },
};
</script>

<style scoped lang="scss">
.driver-list {
 padding: 10px;
}
</style>
