// import Login from "@/components/Login/Login";
// import Dashboard from "@/components/dashboard/Dashboard";


const Login = () => import('@/components/Login/Login')
const Dashboard = () => import('@/components/dashboard/Dashboard')


export const routes = [
    {
        path:'/login',
        component: Login,
        meta: {
            auth:false,
        }
    },
    {
        path:'/dashboard',
        component: Dashboard,
        meta: {
            auth: true
        }
    },
]