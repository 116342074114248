<template>
  <div class="branch-list-modal">
    <div class="branch-list-header">
      <span><i class="fas fa-map-marker-alt"></i>Branches</span>
    </div>
    <div class="branch-list-blk">
      <div v-if="branchList.length > 0">
        <div class="branch-list-content-modal" v-for="(data,index) in branchList" :key="index"
             @click="selectBranch(data)" :class="{'selected-branch': branchId === data.id }">
          <span>{{ data.name }}</span>
        </div>
      </div>
      <div v-else class="no-branch-blk">
        <span>No Branch Assigned</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FareList",
  props: {
    branchList: Array
  },
  data: () => {
    return {
      branchId: 0
    }
  },
  watch: {
    branchList() {
      if (this.branchList.length > 0) {
        this.branchId = this.branchList[0].id
      }

    },

  },

  methods: {
    selectBranch(data) {
      this.branchId = data.id
      this.$emit('select-branch', data.id)
    }
  }
}
</script>

<style scoped lang="scss">
.branch-list-modal {
  border: 1px solid #d2d2d2;
  display: flex;
  flex-direction: column;

  .branch-list-header {
    border-bottom: 1px solid #d2d2d2;
    padding: 5px;
    font-weight: bolder;
    font-size: 17px;
    color: #fa7815;

    .fas {
      margin-right: 5px;
    }
  }

  .branch-list-blk {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 150px);
    overflow-y: scroll;

    .branch-list-content-modal {
      padding: 10px;
      font-weight: bold;
      cursor: pointer;
      color: #eea004;
    }

    .selected-branch {
      background-color: #eea004;
      color: white;
    }

    .no-branch-blk {
      display: flex;
      justify-content: center;
      margin-bottom: auto;
      margin-top: auto;

      span {
        color: gray;
        font-weight: bold;
      }
    }
  }
}
</style>