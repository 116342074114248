<template>
  <div>
    <v-card class="start-end-modal">
      <h2 class="start-end-header">{{ status }} Trip</h2>
      <div class="start-end-content">
        <div class="date-time-blk">
          <v-menu
              v-model="dateModel"
              :nudge-right="40"
              :nudge-top="100"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="630px"
              min-width="630px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                  v-model="dateText"
                  outlined
                  label="Date & Time"
                  readonly
                  dense
                  v-on="on"
              >
              </v-text-field>

            </template>
            <div class="date-time-widget-container">
              <h2 class="date-time-header">Date and Time</h2>
              <v-layout row wrap>
                <v-flex xs12 sm6>
                  <v-date-picker v-model="date" color="black"
                  ></v-date-picker>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-time-picker v-model="timePicker" color="black"></v-time-picker>
                </v-flex>
              </v-layout>
              <div class="date-time-button-blk">
                <v-btn color="black" small @click="selectDate">OK</v-btn>
                <v-btn color="black" small @click="cancelDate">Cancel</v-btn>
              </div>
            </div>
          </v-menu>
        </div>
        <div class="reading-blk">
          <v-text-field
              v-model="reading"
              outlined
              dense
              label="Reading"
              :label="status + ' Reading'"
              :placeholder="status + ' Reading'"
              :error-messages="readingError"
              @input="$v.reading.$touch()"
              @blur="$v.reading.$touch()"
          >

          </v-text-field>
        </div>
        <div class="location-blk">
          <VuePlaces
              :flag="1"
              :callBack="locationPlacesPick"
              placeholder="Location"
              ref="locationSource"
              validate
              addressType="geocode"
              :locationData="source.location"
              @validate-parent="clearLocation"
              v-model="source.address"
              :placeStatus="placeStatus"
          ></VuePlaces>
        </div>
        <div class="image-blk">
          <v-file-input
              v-model="readingImage"
              dense
              outlined
              :label="status + ' Reading Image'"
              accept="image/*"
              :error-messages="readingImageError"
              @input="$v.readingImage.$touch()"
              @blur="$v.readingImage.$touch()"
          >

          </v-file-input>
        </div>
      </div>
      <div class="start-end-button-blk">
        <v-btn color="#016401" @click="submitValue" :disabled="readingImage === '' || readingImage === null || reading === '' || source.address === ''">{{ status }} Trip</v-btn>
        <v-btn color="#ab0606" @click="closeDialog">Cancel</v-btn>
      </div>
    </v-card>
    <confirm ref="confirm"></confirm>
    <Loading v-if="loading"></Loading>
  </div>
</template>

<script>
import * as moment from "moment";
import VuePlaces from "@/components/VuePlaces";
import tripAxios from '@/axios/axios-trip'
import Confirm from "@/components/common/Confirm";
import Loading from "@/components/common/Loading";
import {validationMixin} from "vuelidate";
import {required,numeric} from "vuelidate/lib/validators";

export default {
  name: "StartEndDialog",
  components: {Loading, Confirm, VuePlaces},
  props: {
    bigId: Number,
    status: String,
  },
  mixins: [validationMixin],
  validations: {
    reading: {required},
    readingImage: {required},
    source: {
      address: {required},
    }
  },
  data: () => {
    return {
      dateModel: false,
      date: moment().format('YYYY-MM-DD'),
      timePicker: moment().format('HH:mm'),
      dateTime: moment().format('YYYY-MM-DD HH:mm'),

      source: {address: "", location: {lat: "", lng: ""}},

      readingImage: null,

      reading: '',

      loading : false,

      placeStatus: false
    }
  },
  methods: {
    cancelDate() {
      this.date = moment().format('YYYY-MM-DD')
      this.timePicker = moment().format('HH:mm')
      this.dateTime = moment().format('YYYY-MM-DD HH:mm')
      this.dateModel = false
    },
    selectDate() {
      this.dateModel = false
      this.dateTime = `${this.date} ${this.timePicker}`
    },
    locationPlacesPick(place) {
      if (place.flag === 1) {
        this.source.location = {
          lat: place.location.lat(),
          lng: place.location.lng()
        };
        this.source.address = place.address;
      }
    },
    clearLocation(flag) {
      if (flag === 1) {
        this.source.location = {lat: "", lng: ""};
        this.source.address = "";
      }
    },
    async submitValue() {
      this.$v.$touch()
      if (this.$v.$anyError) {
      }
      else {
      let confirmTitle = `Confirm ${this.status}`;
      let confirmText = `Please confirm to ${this.status} the trip`;
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        this.loading = true
        try {
          let url = ''
          let name = ''
          if (this.status === 'Start') {
            url = 'android/user/v1/started/sp'
            name = 'start'
          } else {
            url = 'android/user/v1/ended/sp'
            name = 'end'
          }
          const payload = {
            address: this.source.address,
            location: this.source.location,
            time: moment(this.dateTime).valueOf(),
            reading: parseInt(this.reading),
            tripid: this.bigId,
          }
          let formData = new FormData()
          formData.append('param', JSON.stringify(payload))
          formData.append(name, this.readingImage)

          const response = await tripAxios.post(url, formData)
          if (response.data.status === 200) {
            this.$emit('start-end-trip', {id: this.bigId,reading:this.reading, status: this.status,readings: response.data.readings,userCalculation:response.data.userCalculation,userTotal:response.data.userTotal,eTime:response.data.eTime})
            this.loading = false
            this.date = moment().format('YYYY-MM-DD');
            this.timePicker = moment().format('HH:mm');
            this.dateTime = moment().format('YYYY-MM-DD HH:mm');
            this.source.location = {lat: "", lng: ""};
            this.source.address = "";
            this.readingImage = null;
            this.reading = ''
            this.$v.$reset();
            this.placeStatus = true
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000
            });
          } else if(response.data.responsestatus === 204) {
            this.loading = false
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000
            });
          }
        } catch (error) {
          this.loading = false
        }
      }
      }
    },
    closeDialog() {
      this.date = moment().format('YYYY-MM-DD');
      this.timePicker = moment().format('HH:mm');
      this.dateTime = moment().format('YYYY-MM-DD HH:mm');
      this.source.location = {lat: "", lng: ""};
      this.source.address = "";
      this.readingImage = null;
      this.reading = ''
      this.$v.$reset();
      this.$emit('close-dialog')
      this.placeStatus = true
    },

  },
  computed: {
    dateText() {
      return moment(this.dateTime).format('LLL')
    },
    readingError () {
      const errors = []
      if (!this.$v.reading.$dirty) return errors
      !this.$v.reading.required && errors.push('Reading is required.')
      // !this.$v.reading.numeric && errors.push('Enter a numeric value.')
      return errors
    },
    readingImageError() {
      const errors = []
      if (!this.$v.readingImage.$dirty) return errors
      !this.$v.readingImage.required && errors.push('Reading Image is required.')
      return errors
    }
  }
}
</script>

<style scoped lang="scss">
.start-end-modal {
  padding: 10px;

  .start-end-header {
    text-align: center;
    padding: 5px;
  }

  .start-end-content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .date-time-blk {
    display: flex;
    width: 48%;
    background-color: white;
    padding: 0 10px;
  }

  .reading-blk, .image-blk {
    width: 48%;
    padding: 0 10px;
  }

  .location-blk {
    width: 96%;
    padding: 0 10px;
  }

  .start-end-button-blk {
    display: flex;
    flex-direction: row-reverse;
    margin: 10px;

    ::v-deep .v-btn {
      color: white;
      margin: 0 8px;
      font-weight: bold;
    }
  }
}

.date-time-widget-container {
  background-color: white;
  padding: 15px;

  .date-time-header {
    text-align: center;
    padding-bottom: 5px;
    font-weight: bold;
  }

  .date-time-button-blk {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;

    ::v-deep .v-btn {
      color: white;
      margin: 0 8px;
    }
  }
}

.v-picker--date, .v-picker--time {
  padding: 10px;
}
</style>