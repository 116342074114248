<template>
  <div class="spinner-wrapper">
    <div class="spinner">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loading',
  data: () => ({
    loading: false,
  }),
}
</script>

<style scoped="" lang="scss">
.spinner-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: calc(100% + 0px);
  height: calc(100% + 0px);
  background: rgba(#FFF, 0.8);
  z-index: 5;
}
.spinner {
  width: 50px;
  height: 50px;
  //margin: 100px auto;
  background-color: #eea004;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 150;
  margin: auto;

  border-radius: 100%;
  -webkit-animation: sk-scaleout 1.0s infinite ease-in-out;
  animation: sk-scaleout 1.0s infinite ease-in-out;
}

@-webkit-keyframes sk-scaleout {
  0% { -webkit-transform: scale(0) }
  100% {
    -webkit-transform: scale(1.0);
    opacity: 0;
  }
}

@keyframes sk-scaleout {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
  } 100% {
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
      opacity: 0;
    }
}
</style>