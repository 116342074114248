<template>
  <div class="outer-blk">
    <div class="tab-blk">
      <!-- <v-container class="black-background">
    <v-select
      v-model="branch"
      :items="branchList"
      color="white"
      item-text="name"
      item-value="id"
      label="Branch"
      required
      dense 
      class="white-select custom-width "  
    ></v-select>
  </v-container> -->

      <!-- {{ branchList[0].branch }} -->
      <div class="tab-content-modal">
        <div class="filter-blk">
          <v-autocomplete v-model="branch" placeholder="Branch" outlined dense hide-details="auto" :items="branchList" onchange="reload" item-text="name" return-object class="custom-autocomplete" />
        </div>
        <div class="user-content-blk">
          <span @click="getRegisterForm" :class="{ 'selected-tab-blk': selectedTab === 'Account' }">Account</span>
        </div>

        <v-btn color="#eea004" class="logout-button-blk" @click="logout">Logout</v-btn>
      </div>
      <div class="tab-inner-blk" v-if="vendorStatus === '1'">
        <div class="tab-content-blk" v-for="(data, index) in tabItems" :key="index" @click="selectTab(data)" :class="{ 'selected-tab-blk': data === selectedTab, 'not-approved-blk': vendorStatus === '0' || vendorStatus === '2' }">
          <span>{{ data }}</span>
        </div>
      </div>
    </div>

    <div class="dashboard-content-blk">
      <Map v-if="selectedTab === 'Map'"></Map>

      <ToBeAssigned v-if="selectedTab === 'To Be Assigned'"></ToBeAssigned>

      <DriverDashboard v-if="selectedTab === 'Driver'"></DriverDashboard>

      <TripList v-if="selectedTab === 'Trip'"></TripList>

      <VehicleDashboard v-if="selectedTab === 'Vehicle'"></VehicleDashboard>

      <AdminDashboard v-if="selectedTab === 'Admin'"></AdminDashboard>

      <RealTime v-if="selectedTab === 'Realtime Trips'"></RealTime>

      <!--      <PeriodicalReports v-if="selectedTab === 'Reports'"></PeriodicalReports>-->

      <Reports v-if="selectedTab === 'Reports'"></Reports>

      <ReRegister v-if="selectedTab === 'Account'"></ReRegister>
    </div>
    <Confirm ref="confirm"></Confirm>
  </div>
</template>

<script>
import instance from "@/axios/axios-global";
import Map from "../map/Map.vue";
import axios from "@/axios/axios-global";
import ToBeAssigned from "@/components/assign/to-be-assigned/ToBeAssigned";
import DriverDashboard from "@/components/driver-management/driver-dashboard/DriverDashboardNew";
import TripList from "@/components/Trips/trip-list/TripList";
import VehicleDashboard from "@/components/vehicle-management/vehicle-dashboard/VehicleDashboardNew";
import Fare from "@/components/fares/Fare";
import Register from "@/components/Register";
import ReRegister from "@/components/account/ReRegister";
import Reports from "../reports/Reports";
import Confirm from "@/components/common/Confirm";
import AdminDashboard from "@/components/admin/AdminDashboard";

import RealTime from "@/components/realtime/RealTIme";
// import PeriodicalReports from "@/components/reports/PeriodicalReports";

export default {
  name: "Dashboard",
  components: {
    AdminDashboard,
    Confirm,
    ReRegister,
    Register,
    Fare,
    VehicleDashboard,
    TripList,
    DriverDashboard,
    ToBeAssigned,
    Reports,
    Map,
    RealTime,
  },
  data: () => {
    return {
      tabItems: ["Map", "Trip", "Realtime Trips", "To Be Assigned", "Driver", "Vehicle", "Admin", "Fare", "Reports"],
      selectedTab: "To Be Assigned",
      vendorName: "",
      vendorNumber: "",
      vendorStatus: "",
      branchList: [],
      branch: null,
    };
  },
  mounted() {
    const storedTab = localStorage.getItem("selectedTab");
    if (storedTab) {
      this.selectedTab = storedTab;
    } else {
      this.selectedTab = "To Be Assigned";
    }
    const branchListFromStorage = localStorage.getItem("branchList");
    try {
      this.branchList = branchListFromStorage ? JSON.parse(branchListFromStorage) : [];
    } catch (error) {
      console.error("Error parsing branchList from localStorage:", error);
      this.branchList = [];
    }

    if (this.branchList.length > 0) {
      const savedBranch = localStorage.getItem("selectedBranch");
      if (savedBranch) {
        const savedBranchData = JSON.parse(savedBranch);
        this.branch = this.branchList.find((branch) => branch.id === savedBranchData.id) || this.branchList[0];
      } else {
        this.branch = this.branchList[0];
      }
    }

    // this.getBranch()
    this.vendorName = localStorage.getItem("vendorName");
    this.vendorNumber = localStorage.getItem("vendorNumber");
    this.vendorStatus = localStorage.getItem("vendorStatus");
    if (this.vendorStatus === "0" || this.vendorStatus === "2") {
      this.selectedTab = "Account";
    }
  },
  watch: {
    branch(newBranch) {
      if (newBranch) {
        const currentBranch = JSON.parse(localStorage.getItem("selectedBranch"));

        if (!currentBranch || currentBranch.id !== newBranch.id) {
          localStorage.setItem("selectedBranch", JSON.stringify(newBranch));
          localStorage.setItem("selectedTab", this.selectedTab);
          window.location.reload();
        }
      }
    },
  },

  methods: {
    async getBranch() {
      const response = await axios.post("admin/sp/branch");
      console.log(response, ">>>>>>>>>>>>>");
      localStorage.setItem("branchList", JSON.stringify(response.data));
    },
    selectTab(data) {
      if (this.vendorStatus === "1") {
        this.selectedTab = data;
      } else if (this.vendorStatus === "0" || this.vendorStatus === "2") {
        this.selectedTab = "Account";
      }
    },
    async logout() {
      let confirmTitle = "Confirm Logout";
      let confirmText = "Please Confirm to Logout";
      let confirmIcon = "fas fa-exclamation";
      if (
        await this.$refs.confirm.open(confirmTitle, confirmText, {
          icon: confirmIcon,
          color: "red",
          buttonName: "Logout",
        })
      ) {
        instance.defaults.headers.common["token"] = "";

        localStorage.removeItem("vendorLoginToken");
        localStorage.removeItem("vendorName");
        localStorage.removeItem("vendorNumber");
        localStorage.removeItem("vendorStatus");
        localStorage.removeItem("selectedBranch");
        localStorage.removeItem('branchList')

        await this.$router.push("/login");
      }
    },
    getRegisterForm() {
      this.selectedTab = "Account";
    },
  },
};
</script>

<style scoped lang="scss">
.filter-blk {
  width: 200px;
  z-index: 999;
}

.custom-autocomplete {
  color: white;
  //   padding: 8px;
  border-radius: 8px;
}

.v-input {
  border: none;
  background-color: white !important;
}
.v-label {
  padding-top: 6px;
}

@import "./dashboard.scss";
</style>
