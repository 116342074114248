<template>
  <div class="login-outer-blk">
    <div class="login-inner-blk">
      <div class="login-inner-modal">

        <div class="taski-image-blk">
          <img src='../../assets/images/taski-logo.png' class="image-blk">
        </div>

        <div class="taski-name-blk">
          ta<span>S</span>ki Service Partner Portal
        </div>

        <div class="text-field-content-blk">
          <span class="w-10 flex justify-center text-gray-500"><i class="fas fa-user-alt"></i></span>
          <input placeholder="Mobile Number" maxlength="10" v-model="mobile" :disabled="buttonName === 'login'"
                 @keydown.enter="getOtpAndLogin">
        </div>

        <div class="text-field-content-blk" v-if="buttonName === 'login'">
          <span><i class="fas fa-lock"></i></span>
          <input placeholder="OTP" v-model="otp" maxlength="4"
                 @keydown.enter="getOtpAndLogin" id="otp-input">
        </div>
        <div class="login-button-blk">
          <v-btn v-if="buttonName === 'login'" large color="#fcd34d" class="resend-button-blk" @click="resendOtp"
                 :disabled="countDown !== 10">Resend<span v-if="countDown !== 10"> ({{ countDown }})</span></v-btn>
          <v-btn class="button-class" large @click="getOtpAndLogin"
                 :class="{'button-login-blk':buttonName === 'login'}" :disabled="mobile.length < 10">{{ buttonName }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '@/axios/axios-global'

export default {
  name: "Login",
  data: () => {
    return {
      buttonName: 'get otp',
      mobile: '',
      otp: '',

      resendDisable: false,
      countDown: 10,
    }
  },
  watch: {
    mobile() {
      this.mobile = this.mobile.replace(/[^0-9]/g, '')
    },
    otp() {
      this.otp = this.otp.replace(/[^0-9]/g, '')
    }
  },
  methods: {
    async getOtpAndLogin() {
      if (this.buttonName === 'get otp') {
        if (this.mobile.length === 10) {
          try {
            const response = await axios.post('login/mobileValidation', {mobile: this.mobile})
            if (response.status === 200) {
              this.buttonName = 'login'
              setTimeout(() => {
                document.getElementById('otp-input').focus()
              }, 100)

            } else if (response.status === 206) {
              this.$toasted.show(response.data, {
                position: "bottom-center",
                duration: 5000
              });
            }
          } catch (error) {
            this.$toasted.show(error.message, {
              position: "bottom-center",
              duration: 5000
            });
          }
        }
      } else if (this.buttonName === 'login') {
        if (this.otp.length === 4) {
          try {
            const response = await axios.post('login/OTPValidation', {
              mobile: this.mobile,
              otp: this.otp
            })
            if (response.status === 200) {
              let responseData = response.data
              axios.defaults.headers.common['token'] = responseData.token;
              localStorage.setItem("vendorLoginToken", responseData.token);
              localStorage.setItem("vendorName", responseData.name);
              localStorage.setItem("vendorNumber", this.mobile);
              localStorage.setItem('vendorStatus', responseData.status);
              localStorage.setItem('enableETS', responseData.enableETS);
              localStorage.setItem('vendorId', responseData.vendor);
              localStorage.setItem('branchList', JSON.stringify(responseData.branch));
              let vendorToken = localStorage.getItem('vendorLoginToken')
              if (vendorToken === responseData.token) {
                await this.$router.push('/dashboard')
              }
            } else if (response.status === 206) {
              this.$toasted.show(response.data, {
                position: "bottom-center",
                duration: 5000
              });
            }
          } catch (error) {
            this.$toasted.show(error.message, {
              position: "bottom-center",
              duration: 5000
            });
          }
        } else if (this.otp.length < 4) {
          this.$toasted.show('OTP should be at least  4 digits', {
            position: "bottom-center",
            duration: 5000
          });
        }
      }
    },
    async resendOtp() {
      if (this.countDown > 0) {
        if (this.countDown === 10) {
          const response = await axios.post('login/mobileValidation', {mobile: this.mobile})
        }
        setTimeout(async () => {
          this.countDown -= 1
          await this.resendOtp()
        }, 1000)
      } else {
        this.countDown = 10
      }

    }
  }
}
</script>

<style scoped lang="scss">
.login-outer-blk {
  background-color: #fcd34d;

  .login-inner-blk {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .login-inner-modal {
      background-color: white;
      padding: 50px;

      width: 33%;
      border-radius: 5px;
      @media (max-width: 1399px) {
        width: 40%;
      }

      .taski-image-blk {
        display: flex;
        justify-content: center;
        margin-bottom: 30px;

        .image-blk {
          max-height: 70px;
        }
      }

      .taski-name-blk {
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        font-family: Corbel;

        span {
          color: #eea004;
        }
      }

      .text-field-content-blk {
        display: flex;
        align-items: center;
        margin-top: 20px;
        width: 100%;
        padding: 10px;
        color: #4e4e4e;
        background-color: #f5f4f4;
        border-radius: 5px;
      }

      .login-button-blk {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;

        .button-class {
          width: 100%;
          background-color: black;
          color: white;
          font-weight: bold;
          font-size: 16px;
        }

        .button-login-blk {
          width: 33%;
        }

        .resend-button-blk {
          width: 33%;
          font-weight: bold;
          font-size: 16px;
        }
      }
    }
  }
}

.fas {
  text-align: center;
  width: 30px;
}

input {
  padding: 5px;
  width: 95%;
  color: #4e4e4e;
  font-weight: bold;

}

:focus {
  outline: none;
}

::placeholder {
  color: #aeacac;
  font-family: 'Muli', sans-serif;
}

</style>