<template>
  <v-card class="toll-add-modal">
    <div class="toll-blk">
      <span class="toll-header-modal">Toll</span>
      <div class="input-field-modal">
        <div class="input-modal">
          <v-text-field
              v-model="tollAmount"
              placeholder="Toll Amount"
              label="Toll Amount"
              dense
              outlined
              prepend-inner-icon="₹"
              hide-details
          >
          </v-text-field>
        </div>
        <div class="input-modal">
          <input ref="receiptUpload" class="input-blk" @change="selectData" type="file" accept="image/*">
          <v-btn color="#5891df" @click="$refs.receiptUpload.click()" v-if="fileName === ''">Add Receipt</v-btn>
          <div v-if="fileName !== ''" class="file-name-modal">
            <span>{{this.fileName}}</span>
            <i class="fas fa-times" @click="fileName = ''"></i></div>
        </div>
      </div>
      <div class="toll-insert-button-modal">
        <v-btn color="#fa9e15" :disabled="fileName === '' || tollAmount === ''" @click="addToll">Add Toll</v-btn>
      </div>
    </div>
    <Confirm ref="confirm"></Confirm>
    <Loading v-if="loading"></Loading>
  </v-card>
</template>

<script>
import axios from "@/axios/axios-global";
import Confirm from "@/components/common/Confirm";
import Loading from "@/components/common/Loading";

export default {
  name: "TollAdd",
  components: {Loading, Confirm},
  props: {
    tripId: Number
  },
  watch: {
    tripId() {
      this.fileName = ''
      this.tollAmount = ''
    }
  },
  mounted() {
    this.fileName = ''
    this.tollAmount = ''
  },
  data: () => {
    return {
      fileName: '',
      tollAmount: '',
      tollFile: '',

      loading: false,
    }
  },
  methods: {
    selectData(event) {
      let files = event.target.files
      this.fileName  = files[0].name
      this.tollFile = files[0]
      this.$refs.receiptUpload.value = ''
    },
    async addToll() {
      let confirmTitle = "Confirm Add Toll";
      let confirmText = "Please confirm to Add Toll";
      if (await this.$refs.confirm.open(confirmTitle, confirmText)) {
        this.loading = true
        try {
          const payload = {
            tripId: this.tripId,
            toll: this.tollAmount
          }
          let formData = new FormData()
          formData.append('data',JSON.stringify(payload))
          formData.append('toll',this.tollFile)
          const response = await axios.post('/router/tollInsert',formData)
          if(response.status === 200) {
            this.loading = false
            this.$toasted.show(response.data.message, {
              position: "bottom-center",
              duration: 4000
            });
            this.$emit('insert-toll',response.data.data)
            this.tollAmount = ''
            this.fileName = ''
            this.tollFile = ''
          }
        }catch (error) {
          this.loading = false
          // this.$toasted.show(error.response.data, {
          //   position: "bottom-center",
          //   duration: 4000
          // });
        }
      }

    },
  }
}
</script>

<style scoped lang="scss">
.toll-add-modal {
  border-radius: 0;
  padding: 10px;

  .toll-blk {
    display: flex;
    flex-direction: column;
    width: 100%;

    .toll-header-modal {
      font-weight: bold;
      font-size: 18px;
      border-bottom: 1px solid #e0dede;
      width: 100%;
      padding: 5px 0;
    }

    .input-field-modal {
      padding-top: 15px;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .input-modal {
        width: 48%;
        display: flex;
        align-items: center;

        .v-btn {
          margin-top: 2px;
        }
        .file-name-modal {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          padding: 7px;
          width: 100%;
        }
        span {
          color: #4e96be;
          margin-right: 5px;
          max-width: 94%;
          border-right: none;
        }
        .fas {
          cursor: pointer;
        }
      }
    }
    .toll-insert-button-modal {
      display: flex;
      flex-direction: row-reverse;
      padding: 0 10px;
      margin-top: 5px;
    }
    ::v-deep.v-icon.v-icon {
      font-size: 16px;
    }
  }
}

::v-deep .v-input__icon {
  width: 10px !important;
  min-width: 10px !important;
  margin-right: 5px;
}
</style>