<template>
  <div class="fare-content-list-modal" v-if="fareDetails.branch">
    <h3>{{ fareDetails.branch }}</h3>

    <div class="category-content-modal" v-for="(data,index) in fareDetails.fareList" :key="index" >
<!--      <span class="category-blk" v-if="data.category === 'Plus'">Category: <strong>{{data.category}}</strong></span>-->
      <div :class="{'plus-category-blk': data.category !== 'Plus'}" class="category-list-modal">
      <div class="fare-header-content">
        <span class="header-blk">Hour</span>
        <span class="header-blk">KM</span>
        <span class="header-blk">Extra Km</span>
        <span class="header-blk">Extra Minute</span>
        <span class="header-blk">Base Price</span>
      </div>
      <div class="fare-list-content" v-for="(fare,index) in data.categoryFare" :key="index">
        <span class="content-blk">{{fare.hour}}</span>
        <span class="content-blk">{{fare.km}}</span>
        <span class="content-blk">{{fare.perkm}}</span>
        <span class="content-blk">{{fare.permin}}</span>
        <span class="content-blk">{{fare.basefare}}</span>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/axios/axios-global";

export default {
  name: "FareDetails",
  props: {
    branchId: Number
  },
  data: () => {
    return {
      fareDetails: '',
    }
  },
  watch: {
    async branchId() {
      await this.getFareDetails()
    }
  },
  methods: {
    async getFareDetails() {
      const response = await axios.post('settings/branchFare', {id: this.branchId})
      this.fareDetails = response.data.fares
    },
  },
}
</script>

<style scoped lang="scss">
.fare-content-list-modal {
  padding: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #d2d2d2;
  height: calc(100vh - 110px);
  overflow-y: scroll;
  .category-content-modal {
    display: flex;
    flex-direction: column;
    //padding: 10px 0;
    //.category-blk {
    //  font-weight: bolder;
    //  strong {
    //    color: #fa7815;
    //  }
    //}
    .fare-header-content {
      display: flex;
      background-color: #f8b318;
      color: black;
      border: 1px solid black;
      .header-blk {
        border-right: 1px solid black;
        padding: 10px;
        width: 20%;
        text-align: center;
        font-weight: bold;
      }
      :last-child {
        border-right: none;
      }
    }
    .fare-list-content {
      display: flex;
      border: 1px solid black;
      border-top: none;
      .content-blk {
        border-right: 1px solid black;
        padding: 10px;
        width: 20%;
        text-align: center;
      }
      :last-child {
        border-right: none;
      }
    }
  }
  .category-list-modal {
    padding: 10px 0;
  }
  .plus-category-blk {
    display: none;
    padding: 0;
  }
}

</style>